import styled from 'styled-components';
import Button from '../../../../common/components/Button';

export const AboutContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 100px 2%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  background: #eee9e9;
  padding: 24px 50px;
  border-radius: 8px;
  margin-bottom: 140px;

  @media (max-width: 1080px) {
    align-items: flex-end;
    flex-direction: column-reverse;
  }

  
  @media (max-width: 600px) {
    padding: 24px 18px;
  }
`;

export const SubTitle = styled.span`
  font-weight: 500;
  border-bottom: solid 2px #407aea;
  position: absolute;
  top: -70px;
  left: 4px;

  @media (max-width: 1280px) {
    top: -20px;
  }
`;

export const Icon = styled.img``;

export const IconContainer = styled.div`
  max-width: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Text = styled.p`
  font-weight: 400;
  margin-bottom: 30px;
`;

export const AboutContent = styled.div`
  max-width: 600px;
  position: relative;

  @media (max-width: 1080px) {
    align-self: flex-start;
    margin-top: 50px;
  }
`;

export const Title = styled.h1`
  color: #000;
  font-size: 44px;
  font-weight: 500;

  @media (max-width: 1420px) {
    font-size: 42px;
  }

  @media (max-width: 1080px) {
    font-size: 34px;
  }

  @media (max-width: 525px) {
    font-size: 30px;
  }
`;

export const AboutTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;

  h1 {
    margin-bottom: 10px;
    font-size: 38px;
    text-align: center;
  }

  p {
    max-width: 1100px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }
`;

export const Image = styled.img.attrs({
  src: '/images/example-omni-2.png',
  alt: 'Omnichannel',
})`
  min-width: 650px;
  max-width: 100%;
  position: relative;
  right: -15px;
  padding-left: 15px;

  @media (max-width: 1080px) {
    min-width: unset;
    justify-self: flex-end;
    max-width: 680px;
    position: relative;
    padding-left: 0;
    right: -100px;
  }

  @media (max-width: 690px) {
    max-width: 590px;
  }

  @media (max-width: 565px) {
    max-width: 500px;
  }

  @media (max-width: 455px) {
    max-width: 440px;
  }

  @media (max-width: 385px) {
    max-width: 390px;
  }

  @media (max-width: 330px) {
    max-width: 320px;
  }
`;

export const CustomButton = styled(Button)`
  font-weight: 400;
`;

export const AboutOmnichannel = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  gap: 20px;

  h1 {
    margin-top: 0;
    max-width: 500px;
  }

  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 0 2%;
`;

export const Span = styled.span`
  color: #407AEA;
`;

export const ServiceDescription = styled.div`
  h1 {
    font-weight: 500;
    font-size: 26px;
    color: #161515;
    margin-top: 0;
  }
`;

export const Service = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 35px;
  border-bottom: solid 1px #e6dfdf;
  padding-top: 30px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
  }

  span {
    color: #cac0c0;
    font-weight: 500;
    font-size: 12px;
    position: relative;
    top: 5px;
  }
`;
