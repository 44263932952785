import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: ${({ expandContainer }) => (expandContainer ? '100%' : 'calc(100% - 300px)')};
  margin: 100px 0;
  margin-top: 165px;
  padding: 0 2%;
  transition: 0.8s ease;
  margin-left: ${({ expandContainer }) => (expandContainer ? 'unset' : '300px')};

  @media (max-width: 960px) {
    width: 100%;
    margin-left: unset;
  }
  @media (max-width: 600px) {
    margin: 50px 0;
  }
`;

export const TitleText = styled.h1`
  font-size: 48px;
  max-width: 560px;
  letter-spacing: -0.04em;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 32px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media (max-width: 970px) {
    max-width: 100%;
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }

  @media (max-width: 465px) {
    font-size: max(35px, min(calc(-164px + 16.875vw), 160px));
  }

  @media (max-width: 370px) {
    font-size: max(30px, min(calc(-164px + 16.875vw), 160px));
  }
`;

export const Text = styled.p`
  color: #878c9b;
  margin-top: 10px;
  max-width: 450px;
  width: 100%;
  font-size: 18px;
  line-height: 35px;

  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 32px;
  }
`;

export const CardText = styled.p`
  color: #000000;
  max-width: 450px;
  width: 100%;
  font-size: 24px;
  line-height: 50px;

  @media (max-width: 1175px) {
    max-width: 100%;
    margin-top: 0;
    font-size: 18px;
    line-height: 32px;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

export const SideText = styled.div`
  max-width: 450px;
  width: 100%;
  padding: ${({ reverse }) => (reverse ? '0 0 0 2%' : '0 2% 0 0')};
  
  @media (max-width: 1175px) {
    max-width: 100%;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

export const CardsContainer = styled.div`
  max-width: 1025px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 200px;
  margin-bottom: 200px;
  @media (max-width: 960px) {
    position: relative;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 100px;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    max-width: 100%;
  }

  @media (max-width: 600px) {
    margin-top: -50px;
    margin-bottom: 50px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

  @media (max-width: 600px) {
    margin-top: ${({ noMargin }) => (noMargin ? '0;' : '75px;')}
  }

  @media (max-width: 1175px) {
    width: 100%;
    flex-direction: column-reverse;
    padding: 12px;
  }
`;

export const Card = styled.div`
  background-color: ${({ communicationCard }) => (communicationCard ? '#F3DE1B' : '#E6E6E6')};
  max-width: 450px;
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 16px;
  overflow: hidden;
  margin-top: ${({ secondCard }) => (secondCard ? '-75px' : '30px')};

  @media (max-width: 960px) {
    position: relative;
  }

  ${({ telescopeCard }) => telescopeCard && css`
    background: url('/images/stars.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  `}

  @media screen and (max-width: 1175px) {
    margin-top: 25px;
    max-width: 100%;
    max-height: 370px;
  }
`;

export const CardContent = styled.div`
  width: 100%;
  z-index: 1;
  padding: 0 6%;
  position: relative;
  top: -30px;

  ${({ communicationCard }) => communicationCard && css`
    top: 100px;

    @media (max-width: 1228px) {
      top: 65px;
    }

    @media (max-width: 1175px) {
      top: -30px;
    }
  `}

  ${({ dnaCard }) => dnaCard && css`
    display: flex;
    justify-content: flex-end;
  `}

  @media (max-width: 960px) {
    position: absolute;
    top: unset;
    bottom: 14px;
    left: 0;

    ${({ dnaCard }) => dnaCard && css`
      right: 0;
    `}
  }
`;

export const CardImage = styled.img`
  width: 370px;
  height: 100%;

  ${({ telescopeCard }) => telescopeCard && css`
    margin-right: -70px;
    margin-top: 90px;

    @media (max-width: 780px) {
      margin-top: 50px;
    }
  `}

  ${({ communicationCard }) => communicationCard && css`
    margin-right: -100px;
    margin-top: -30px;
  `}

  ${({ dnaCard }) => dnaCard && css`
    width: 600px;
    height: 100%;
    margin-left: -620px;
    margin-top: 240px;

    @media (max-width: 1175px) {
      margin-left: -1100px;
      margin-top: 60px;
    }

    @media (max-width: 960px) {
      margin-left: -1000px;
      width: 500px;
      margin-top: 120px;
    }

    @media (max-width: 780px) {
      margin-left: -900px;
    }

    @media (max-width: 650px) {
      margin-left: -820px;
    }

    @media (max-width: 600px) {
      width: 450px;
      height: 280px;
      margin-left: -720px;
    }

    @media (max-width: 540px) {
      margin-left: -600px;
      margin-top: 130px;
    }

    @media (max-width: 450px) {
      margin-left: -500px;
    }
  `}
`;

export const CardTitle = styled.h1`
  font-size: 44px;
  margin-bottom: 10px;
  text-align: ${({ dnaCard }) => (dnaCard ? 'right' : 'left')};
  color: ${({ normalText }) => (normalText ? '#000000' : '#fff')};
  max-width: 450px;

  @media (max-width: 465px) {
    font-size: max(35px, min(calc(-164px + 16.875vw), 160px));
  }
`;
