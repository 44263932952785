import styled from 'styled-components';

export const Integrations = styled.div`
  margin-top: 50px;
  background-color: #407AEA;
  width: 100%;
  padding: 24px 2%;
  display: flex;
  align-items: flex-start;
  gap: 26px;
  justify-content: space-between;

  @media (max-width: 960px) {
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

export const Icon = styled.img`
  max-width: 50px;
  width: 100%;

  @media (max-width: 780px) {
    max-width: 38px;
  }
`;

export const IconContainer = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ facebookIcon }) => (facebookIcon ? '25px' : '15px')};

  @media (max-width: 960px) {
    max-width: 100%;
    gap: 15px;
  }
`;

export const Text = styled.p`
  color: #fff;

  @media (max-width: 595px) {
    font-size: 14px;
  }
`;
