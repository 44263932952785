import styled from 'styled-components';
import Button from '../../../../common/components/Button';

export const Container = styled.div`
  width: 100%;
  padding: 100px 2%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #407aea;
  /* overflow: hidden; */

  @media (max-width: 1080px) {
    align-items: flex-end;
    flex-direction: column;
  }

  @media (max-width: 780px) {
    align-items: center;
    padding-top: 130px;
    height: 100vh;
  }
`;

export const SubTitle = styled.span`
  color: #fff;
  font-weight: 500;
  position: absolute;
  top: -70px;
  left: 5px;

  @media (max-width: 1280px) {
    top: -5px;
  }
`;

export const Icon = styled.img``;

export const IconContainer = styled.div`
  max-width: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Text = styled.p`
  font-weight: 400;
  margin-bottom: 30px;
  color: #fff;
`;

export const AboutContent = styled.div`
  max-width: 600px;
  position: relative;

  @media (max-width: 1080px) {
    align-self: flex-start;
  }

  @media (max-width: 1080px) {
    margin-bottom: 30px;
  }
`;

export const Title = styled.h1`
  color: #000;
  font-size: 44px;
  font-weight: 500;
  color: #fff;

  @media (max-width: 1420px) {
    font-size: 42px;
  }

  @media (max-width: 1080px) {
    font-size: 34px;
  }

  @media (max-width: 525px) {
    font-size: 30px;
  }
`;

export const Image = styled.img.attrs({
  src: '/images/services.png',
  alt: 'Services',
})`
  min-width: 500px;
  max-width: 960px;
  padding-left: 100px;
  margin: 40px 0;

  @media (max-width: 1440px) {
    max-width: 100%;
  }

  @media (max-width: 1080px) {
    min-width: unset;
    justify-self: flex-end;
    max-width: 680px;
    padding-left: 0;
    position: relative;
    right: -100px;
  }

  @media (max-width: 780px) {
    position: unset;
    max-width: 600px;
    width: 100%;
    justify-self: center;
    right: 0;
  }

  /* @media (max-width: 620px) {
    max-width: 590px;
  }

  @media (max-width: 525px) {
    max-width: 500px;
  }

  @media (max-width: 415px) {
    max-width: 470px;
  }

  @media (max-width: 375px) {
    max-width: 430px;
  }

  @media (max-width: 330px) {
    width: 100%;
  } */
`;

export const CustomButton = styled(Button)`
  font-weight: 400;
`;
