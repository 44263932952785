import styled from 'styled-components';
import Button from '../../../../common/components/Button';

export const Title = styled.h1`
  color: #000;
  font-size: 46px;
  font-weight: 500;
  margin-bottom: 25px;

  @media (max-width: 1420px) {
    font-size: 42px;
  }

  @media (max-width: 1080px) {
    font-size: 34px;
  }

  @media (max-width: 525px) {
    font-size: 30px;
  }
`;

export const OmnichannelTitle = styled(Title)`
  color: #407AEA;
  margin-bottom: -30px;

  @media (max-width: 1080px) {
    margin-bottom: -20px;
  }
`;

export const LandingText = styled.div`
  max-width: 650px;
  padding-right: 20px;

  @media (max-width: 1080px) {
    align-self: flex-start;
    padding-right: 0;
  }
`;

export const CustomButton = styled(Button)`
  font-weight: 400;
`;

export const Text = styled.p`
  font-weight: 400;
  max-width: 600px
`;
