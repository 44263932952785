import React from 'react';
import Proptypes from 'prop-types';

import {
  Container,
  LineContainer,
  HightLight,
  TitleText,
} from './style';

const strings = {
  // titleL1: 'Ajudamos empresas',
  // titleL2: 'a impactarem pessoas.',
  titleL1: 'Ajudamos empresas',
  titleL2: 'a impactar positivamente a',
  titleL3: 'vida das pessoas.',
  hightLight1: 'Squad as a Service',
  hightLight2: 'Consultoria ágil',
  hightLight3: 'Outsourcing',
};

function Title({ reduce, hidden }) {
  return (
    <Container>
      <LineContainer animate={reduce ? 'hidden' : 'show'}>
        <HightLight>{strings.hightLight1}</HightLight>
        <HightLight>{strings.hightLight2}</HightLight>
        <HightLight>{strings.hightLight3}</HightLight>
      </LineContainer>
      <TitleText
        animate={reduce ? 'reduce' : 'expand'}
        reduceTitle={reduce}
        hiddenTitle={hidden}
      >
        {strings.titleL1}
        <br />
        {strings.titleL2}
        <br />
        {strings.titleL3}
      </TitleText>
    </Container>
  );
}

Title.defaultProps = {
  hidden: false,
  reduce: false,
};

Title.propTypes = {
  reduce: Proptypes.bool,
  hidden: Proptypes.bool,
};

export default Title;
