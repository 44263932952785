import React, { useState, useEffect, useRef } from 'react';
import { useElementScroll } from 'framer-motion';
import { Element, scroller } from 'react-scroll';
import { useInView } from 'react-intersection-observer';
import { ParallaxProvider } from 'react-scroll-parallax';
import Header from '../../containers/header';
import Contact from '../../containers/contactContainer';
import ModalContent from '../../containers/Modal';
import Stack from './components/stack';
import { costumerCases, products } from '../../common/constants/staticData';
import Carrosel from '../../containers/Carrosel';
import Presentation from './containers/presentation';
import WhatWeDo from './containers/whatWeDo';
import { Overlay, Container } from './style';
import Plan from './components/plan';

function Home() {
  const mainContainer = useRef(null);
  const [scrollContainer, setScrollContainer] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const contentScroll = useRef();
  const smallMobileDevices = window.matchMedia('(max-width: 960px)');
  const mobile = smallMobileDevices.matches;
  const planContainerRef = useRef(null);

  const options = {
    delay: 50,
    threshold: 0.92,
  };

  const linkOptions = {
    threshold: 0.5,
  };

  const { ref: whatWeDoRef, inView: whatWeDo } = useInView(options);
  const { ref: stackRef, inView: stack } = useInView(options);
  const { ref: projectsRef, inView: projectsLink } = useInView(linkOptions);
  const { ref: whatWeDoSection, inView: whatWeDoLink } = useInView(linkOptions);

  let scrollRef;

  if (!smallMobileDevices.matches) {
    const { scrollY } = useElementScroll(contentScroll);
    scrollRef = scrollY;
  }

  const [modal, setModal] = useState(false);
  const [section, setSection] = useState(0);
  const [caseId, setCaseId] = useState(0);
  let canChangeSection = false;
  const scrollCharge = 0;

  const openModal = (open) => {
    if (open) {
      setModal(open);
      setSection('');
      window.modalActive = true;
    }
  };

  const scrollToContainer = (container) => {
    scroller.scrollTo(container, {
      containerId: 'scroll-container',
      duration: 950,
      delay: 1,
      smooth: true,
    });
  };

  const closeModal = () => {
    window.modalActive = false;
    setModal('');
  };

  useEffect(() => {
    setScrollContainer(mainContainer.current);
  }, []);

  const handleModalData = () => {
    if (modal === 'costumer-case') return costumerCases[caseId];
    return products[caseId];
  };

  const carrosselModalIsOpen = () => {
    if (modal === 'costumer-case' || modal === 'products') return true;
    return false;
  };

  return (
    <ParallaxProvider scrollContainer={scrollContainer}>
      <Container ref={mainContainer}>
        <Contact open={modal === 'contact'} handleClose={closeModal} />
        <Header
          open={modal === 'contact'}
          handleOpen={(modalName) => openModal(modalName)}
          handleClose={() => closeModal()}
          darkMode={whatWeDo || stack}
          section={section}
          onChangeSection={scrollToContainer}
          showLinks={showContent}
          projectsLink={projectsLink}
          stackLink={stack}
          whatWeDoLink={whatWeDoLink}
        />
        <Overlay onClick={() => closeModal()} open={modal === 'contact'} />

        <Presentation
          section={scrollCharge}
          contentScroll={contentScroll}
          onEnd={(value) => {
            canChangeSection = value;
          }}
          canChangeSection={canChangeSection}
          planContainer={planContainerRef}
          setShowContent={setShowContent}
          showContent={showContent}
          setHidden={setHidden}
          hidden={hidden}
        >
          <Element name="plan">
            <Plan scrollRef={scrollRef} planContainer={planContainerRef} hidden={hidden} />
          </Element>
          <Element name="whatwedo">
            <WhatWeDo
              show={mobile || section > 0}
              sectionRef={whatWeDoRef}
              whatWeDoSection={whatWeDoSection}
            />
          </Element>
          <Element name="projects">
            <Carrosel
              show={mobile || section > 1}
              caseId={setCaseId}
              openModal={(modalName) => openModal(modalName)}
              showImages="costumer-case"
              title="Clientes que já atendemos"
              sectionRef={projectsRef}
            />
          </Element>
          <Element name="stack">
            <Stack show={mobile || section > 2} sectionRef={stackRef} />
          </Element>
        </Presentation>
        {window.innerWidth < 960 && (
          <>
            <Plan scrollRef={scrollRef} planContainer={planContainerRef} />
            <WhatWeDo show={mobile || section > 0} sectionRef={whatWeDoRef} />
            <Carrosel
              show={mobile || section > 1}
              caseId={setCaseId}
              openModal={(modalName) => openModal(modalName)}
              showImages="costumer-case"
              title="Clientes que já atendemos"
              sectionRef={projectsRef}
            />
            <Stack show={mobile || section > 2} sectionRef={stackRef} />
          </>
        )}
      </Container>
      {carrosselModalIsOpen() && (
        <ModalContent
          open={modal === 'costumer-case' || 'products'}
          modal={modal}
          closeModal={() => closeModal()}
          data={handleModalData()}
        />
      )}
    </ParallaxProvider>
  );
}

export default Home;
