import React from 'react';

import {
  MainContainer,
  I9Image,
  CardContent,
  CardIcon,
  Title,
  Description,
  CustomButton,
  ButtonContainer,
} from './style';

function I9ExcelIntegration() {
  const contactRedirect = () => {
    window.location.href = 'https://forms.office.com/Pages/ResponsePage.aspx?id=4RvYq0R4TUec2ZqRTgFTb7pY9xk6bRlIn_rl0dpdsQhUNUFBTEcyR1czVzNRQTM2Tzk1R1dNQ0VQNiQlQCN0PWcu';
  };

  return (
    <MainContainer>
      <I9Image />
      <CardContent>
        <CardIcon />
        <Title>
          Precisou de planilha?
          <br />
          Ta na mão!
        </Title>
        <Description>
          Com o i9.Platform absolutamente tudo pode ser facilmente exportado para Excel!
        </Description>
        <ButtonContainer>
          <CustomButton
            darkMode={false}
            action={() => contactRedirect()}
            text="Entre em contato"
            primaryBtn
          />
        </ButtonContainer>
      </CardContent>
    </MainContainer>
  );
}

export default I9ExcelIntegration;
