import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import GlobalStyle from './common/theme';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <StrictMode>
    <GlobalStyle />
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
