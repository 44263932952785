import React from 'react';
import {
  SubTitle,
  Title,
  AboutContainer,
  ParallaxImage,
  Card,
  CardsContent,
  CardText,
  CardImage,
  AnimatedImage,
  PaymentTitle,
  PaymentText,
} from './style';

function AboutPlatform() {
  return (
    <AboutContainer>
      <ParallaxImage>
        <AnimatedImage src="/images/i9.png" />
      </ParallaxImage>
      <div>
        <Title>ERP Completo!</Title>
        <Card>
          <CardImage src="/images/shopping-cart.png" />
          <CardsContent>
            <SubTitle>Controle de vendas</SubTitle>
            <CardText>
              Gestão e controle de vendas da i9.Platform garante agilidade.
            </CardText>
          </CardsContent>
        </Card>
        <Card>
          <CardImage src="/images/package.png" />
          <CardsContent>
            <SubTitle>Controle de estoque</SubTitle>
            <CardText>
              Controle de estoque de seus produtos, peças e insumos de forma simples,
              evitando erros nos processos.
            </CardText>
          </CardsContent>
        </Card>
        <Card>
          <CardImage src="/images/money.png" />
          <CardsContent>
            <SubTitle>Controle Financeiro</SubTitle>
            <CardText>
              Controle de contas a receber. No i9.Platform, você controla as contas a receber
              da empresa. Rápido e simples.
            </CardText>
          </CardsContent>
        </Card>
        <Card>
          <CardImage src="/images/receipt.png" />
          <CardsContent>
            <SubTitle>Emissão de nota fiscal</SubTitle>
            <CardText>
              Agilizamos a emissão de NF-e online para produtos e serviços de sua empresa.
            </CardText>
          </CardsContent>
        </Card>
        <Card>
          <CardImage src="/images/dollar.png" />
          <CardsContent>
            <PaymentTitle>Pagamentos online</PaymentTitle>
            <PaymentText>
              Envie e receba pagamentos online facilmente no seu negócio.
            </PaymentText>
          </CardsContent>
        </Card>
      </div>
    </AboutContainer>
  );
}

export default AboutPlatform;
