import React from 'react';
import {
  Title,
  LandingText,
  // CustomButton,
  OmnichannelTitle,
  Text,
} from './style';

function LandingMessage() {
  return (
    <LandingText>
      <OmnichannelTitle>i9.Platform</OmnichannelTitle>
      <Title>Exatamente o que você precisa para ter o controle do seu negócio</Title>
      <Text>
        Um sistema completo e flexível para gerenciar todas as demandas do seu
        negócio. E o melhor, totalmente integrado com o Microsoft 365®
      </Text>
      {/* <CustomButton text="Veja Mais" lightText /> */}
    </LandingText>
  );
}

export default LandingMessage;
