import styled from 'styled-components';

export const AboutContainer = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  @media (max-width: 1080px) {
    flex-direction: column-reverse;
    align-items: center;
    margin: 100px 0;
  }
`;

export const Card = styled.div`
  padding: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px rgba(177, 171, 171, 0.2);
  border-radius: 16px;
  min-height: 155px;
  max-height: 155px;
  width: 100%;
  margin-top: 38px;
  max-width: 480px;

  @media (max-width: 1080px) {
    max-height: 100%;
    max-width: 600px;
  }

  @media (max-width: 475px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const ParallaxImage = styled.div`
`;

export const AnimatedImage = styled.img`
  position: sticky;
  position: -webkit-sticky;
  top: 125px;
  @media (max-width: 1080px) {
    max-width: 100%;
    margin-top: 100px;
  }
`;

export const CardsContent = styled.div`
  @media (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  @media (max-width: 475px) {
    gap: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  background: #eee9e9;
  padding: 24px 50px;
  border-radius: 8px;
  margin-bottom: 140px;

  @media (max-width: 1080px) {
    align-items: flex-end;
    flex-direction: column-reverse;
  }

  @media (max-width: 600px) {
    padding: 24px 18px;
  }
`;

export const SubTitle = styled.span`
  color: #000;
  font-size: 20px;
  font-weight: 800;

  @media (max-width: 460px) {
    font-size: 18px;
  }

  @media (max-width: 475px) {
    text-align: center;
  }
`;

export const CardText = styled.p`
  color: #000000;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  
  @media (max-width: 1175px) {
    max-width: 100%;
    margin-top: 0;
    font-size: 18px;
    line-height: 32px;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }

  @media (max-width: 475px) {
    margin-top: 20px;
  }
`;

export const CardImage = styled.img`
  margin-right: 22px;
  @media (max-width: 475px) {
    margin-right: 0;
  }
`;

export const Title = styled.h1`
  color: #000;
  font-size: 44px;
  font-weight: 500;

  @media (max-width: 1420px) {
    font-size: 42px;
  }

  @media (max-width: 1080px) {
    font-size: 34px;
  }

  @media (max-width: 525px) {
    font-size: 30px;
  }
`;

export const PaymentTitle = styled(SubTitle)`
  margin-left: -10px;
  @media (max-width: 475px) {
    margin-left: 0;
  }
`;

export const PaymentText = styled(CardText)`
  margin-left: -10px;
  @media (max-width: 475px) {
    margin-left: 0;
  }
`;
