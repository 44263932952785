import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 120px;
  min-height: 600px;
  display: flex;
  align-items: center;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
`;

export const LandingContent = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;

  @media (max-width: 1080px) {
    height: 100%;
  }
`;

export const LandingExample = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 2%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1080px) {
    align-items: flex-end;
    flex-direction: column-reverse;
  }
`;
