import React from 'react';
import PropTypes from 'prop-types';
import About from './componentes/aboutProduct';
import Footer from './componentes/footer';
import IntegrationIcons from './componentes/integrations';
import ExampleImage from './componentes/landingImage';
import LandingMessage from './componentes/landingText';
import { Container, LandingExample, LandingContent } from './style';

function Omnichannel({ footerRef }) {
  return (
    <Container>
      <LandingContent>
        <LandingExample>
          <LandingMessage />
          <ExampleImage />
        </LandingExample>
        <IntegrationIcons />
      </LandingContent>
      <About />
      <Footer footerRef={footerRef} />
    </Container>
  );
}

Omnichannel.defaultProps = {
  footerRef: null,
};

Omnichannel.propTypes = {
  footerRef: PropTypes.any,
};

export default Omnichannel;
