import styled, { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';

export default createGlobalStyle`
  @font-face {
    font-family: "LexendDeca";
    src: url('/fonts/LexendDeca-Thin.woff2') format('woff2');
    font-weight: 100;
  }
  @font-face {
    font-family: "LexendDeca";
    src: url('/fonts/LexendDeca-ExtraLight.woff2') format('woff2');
    font-weight: 200;
  }
  @font-face {
    font-family: "LexendDeca";
    src: url('/fonts/LexendDeca-Light.woff2') format('woff2');
    font-weight: 300;
  }
  @font-face {
    font-family: "LexendDeca";
    src: url('/fonts/LexendDeca-Regular.woff2') format('woff2');
  }
  @font-face {
    font-family: "LexendDeca";
    src: url('/fonts/LexendDeca-Medium.woff2') format('woff2');
    font-weight: 500;
  }
  @font-face {
    font-family: "LexendDeca";
    src: url('/fonts/LexendDeca-SemiBold.woff2') format('woff2');
    font-weight: 600;
  }
  @font-face {
    font-family: "LexendDeca";
    src: url('/fonts/LexendDeca-Bold.woff2') format('woff2');
    font-weight: 700;
  }
  @font-face {
    font-family: "LexendDeca";
    src: url('/fonts/LexendDeca-ExtraBold.woff2') format('woff2');
    font-weight: 800;
  }
  @font-face {
    font-family: "LexendDeca";
    src: url('/fonts/LexendDeca-Black.woff2') format('woff2');
    font-weight: 900;
  }

  @font-face {
    font-family: "Swiss721";
    src:  url('/fonts/Swiss-721-Thin.woff2') format('woff2');
    font-weight: 100;
  }
  @font-face {
    font-family: "Swiss721";
    src: url('/fonts/Swiss-721-Light.woff2') format('woff2');
    font-weight: 300;
  }
  @font-face {
    font-family: "Swiss721";
    src: url('/fonts/Swiss-721-Medium.woff2') format('woff2');
  }
  @font-face {
    font-family: "Swiss721";
    src: url('/fonts/Swiss-721-Bold.woff2') format('woff2');
    font-weight: 500;
  }
  @font-face {
    font-family: "Swiss721";
    src: url('/fonts/Swiss-721-Heavy.woff2') format('woff2');
    font-weight: 600;
  }
  @font-face {
    font-family: "Swiss721";
    src: url('/fonts/Swiss-721-Black.woff2') format('woff2');
    font-weight: 700;
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    color: #191a1b;
    font-family: "LexendDeca", LexendDeca,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    font-weight: 200;
    box-sizing: border-box;
    background-color: #f2f2f2;
    min-width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
  }

  textarea:focus, input:focus{
    outline: none;
  }
`;

export const UpAnimContainer = styled(motion.div).attrs({
  animate: { height: 'fit-content' },
  transition: { delay: 0.3, duration: 0.5 },
})`
  height: 0;
  overflow: hidden;
`;

export const SideAnimContainer = styled(motion.div).attrs({
  animate: { width: 'fit-content' },
  transition: { delay: 0.3, duration: 0.5 },
})`
  width: 0px;
  overflow: hidden;
`;

export const FadeAnimContainer = styled(motion.div).attrs({
  animate: { opacity: 1 },
  transition: { delay: 0.3, duration: 0.5 },
})`
  opacity: 0;
`;

// Texts

export const Title1 = styled.h1`
  font-family: "LexendDeca";
  font-weight: 500;
  font-size: max(90px, min(calc(-164px + 16.875vw),160px));
`;

export const Title2 = styled.h2`
  font-family: "LexendDeca";
  font-weight: 700;
  font-size: max(40px, min(3.125vw, 60px));
`;

export const Title3 = styled.h3`
  font-family: "LexendDeca";
  font-weight: 500;
  font-size: max(24px, min(3.125vw, 32px));
`;

export const CommonText = styled.p`
  font-size: max(20px, min(1.5625vw, 30px));
  margin: 0;
`;

export const HightLight = styled.div`
  padding: 6px 12px;
  border-radius: 50px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-right: 6px;
  word-break: keep-all;
  white-space: nowrap;

  @media (max-width: 465px) {
    margin-top: 6px;
  }
`;
