/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
// import CustomButton from '../customButton';

import {
  Container,
  Title,
  Text,
  Icon,
  ContactInfo,
  CloseButton,
  CloseIcon,
  // ContactForm,
  // InputGroup,
  // Input,
  // TextArea,
  // InputContainer,
  // InputCheckBox,
  // ButtonContainer,
  // CheckBoxContainer,
} from './style';

const strings = {
  title: 'Envie uma mensagem',
  text: 'Lorem ipsum lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsum',
  email: 'contato@i9tech.co',
};
function Contact({ open, handleClose }) {
  return (
    <Container open={open}>
      <CloseButton onClick={handleClose}><CloseIcon /></CloseButton>
      <Title>{strings.title}</Title>
      <ContactInfo>
        <Icon />
        <Text>{strings.email}</Text>
      </ContactInfo>
      {/* <ContactForm>
        <InputGroup>
          <InputContainer>
            <Input id="primeiro-nome" name="primeiro-nome" type="text" required />
            <label htmlFor="primeiro-nome">Primeiro Nome</label>
          </InputContainer>
          <InputContainer>
            <Input id="sobrenome" name="sobrenome" type="text" required />
            <label htmlFor="sobrenome">Sobrenome</label>
          </InputContainer>
          <InputContainer>
            <Input id="email" name="email" type="email" required />
            <label htmlFor="email">Email</label>
          </InputContainer>
          <InputContainer>
            <Input id="assunto" name="assunto" type="text" required />
            <label htmlFor="assunto">Assunto</label>
          </InputContainer>
          <InputContainer>
            <TextArea id="mensagem" name="mensagem" type="text" required />
            <label htmlFor="mensagem">Mensagem</label>
          </InputContainer>
        </InputGroup>
      </ContactForm>
      <ButtonContainer>
        <CheckBoxContainer>
          <InputCheckBox id="termos-de-privacidade" type="checkbox" />
          <label htmlFor="termos-de-privacidade">
            Eu li e concordo com as políticas de privacidade
          </label>
        </CheckBoxContainer>
        <CustomButton text="Enviar Mensagem" secondaryBtn />
      </ButtonContainer> */}
    </Container>
  );
}

Contact.defaultProps = {
  open: false,
  handleClose: () => null,
};

Contact.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default Contact;
