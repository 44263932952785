import React, { useState } from 'react';
import propTypes from 'prop-types';

import {
  ButtonContainer,
  ButtonBackGround,
  Buttontext,
  Icon,
} from './style';

function Button({
  text,
  action,
  children,
  icon,
  darkMode,
  ...props
}) {
  const [isHover, setIsHover] = useState(false);
  return (
    <ButtonContainer
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={action}
      darkMode={darkMode}
      {...props}
    >
      <ButtonBackGround
        initial="hidden"
        animate={isHover ? 'visible' : 'hidden'}
      />
      {icon && <Icon src={icon} />}
      <Buttontext darkMode={darkMode} {...props}>{text}</Buttontext>
      {children}
    </ButtonContainer>
  );
}

Button.defaultProps = {
  text: '',
  icon: '',
  action: () => null,
  children: null,
  darkMode: false,
};

Button.propTypes = {
  text: propTypes.string,
  icon: propTypes.string,
  action: propTypes.func,
  children: propTypes.node,
  darkMode: propTypes.bool,
};

export default Button;
