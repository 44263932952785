import React from 'react';
import {
  Container,
  SubTitle,
  Title,
  AboutContent,
  Text,
  Image,
  // CustomButton,
  AboutContainer,
  AboutTitle,
  AboutOmnichannel,
  Span,
  Services,
  ServiceDescription,
  Service,
} from './style';

function About() {
  return (
    <AboutContainer>
      <AboutTitle>
        <Title>Todas as suas conversas. Uma plataforma.</Title>
        <Text>
          Utilizamos diariamente plataformas de comunicação para conversar com
          colegas, outras empresas e clientes. A maioria delas não te dá
          controle nenhum sobre seus dados; o i9.Omnichannel sim.
        </Text>
      </AboutTitle>
      <Container>
        <AboutContent>
          <SubTitle>i9.Omnichannel</SubTitle>
          <Title>Descubra o potencial máximo do seu time</Title>
          <Text>
            Reúna mensagens, projetos e tarefas em um só lugar e torne sua
            equipe mais produtiva.
          </Text>
          {/* <CustomButton text="Veja Mais" lightText /> */}
        </AboutContent>
        <Image />
      </Container>
      <AboutOmnichannel>
        <Title>
          Construído para equipes que precisam de
          {' '}
          <Span>controle sobre seus dados</Span>
        </Title>
        <Services>
          <Service>
            <Span>01</Span>
            <ServiceDescription>
              <Title>100% de controle</Title>
              <Text>
                Seja dono de suas conversas. Escolha entre opções de implantação
                seguras que atendem às suas necessidades de segurança e
                compliance.
              </Text>
            </ServiceDescription>
          </Service>
          <Service>
            <Span>02</Span>
            <ServiceDescription>
              <Title>100% de privacidade</Title>
              <Text>
                Criptografia ponta-a-ponta, certificação ISO 27001 e
                conformidade com GDPR, CCPA, LGPD, e HIPAA.
              </Text>
            </ServiceDescription>
          </Service>
          <Service>
            <Span>03</Span>
            <ServiceDescription>
              <Title>100% personalizável</Title>
              <Text>
                Integre o i9.Omnichannel com seu ecossistema utilizando nossa
                API aberta. Utilize diversas opções de personalização e
                whitelabeling.
              </Text>
            </ServiceDescription>
          </Service>
        </Services>
      </AboutOmnichannel>
    </AboutContainer>
  );
}

export default About;
