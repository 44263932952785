import React, { useRef, useState, useLayoutEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';
import PropTypes from 'prop-types';
import {
  useViewportScroll,
  useTransform,
  useSpring,
  motion,
} from 'framer-motion';

const rand = (min = 0, max = 100) => Math.floor(Math.random() * (+max - +min)) + +min;

function ParallaxContainer({ children, scrollRef }) {
  const mobile = window.matchMedia('(max-width: 960px)');
  const { scrollY } = useViewportScroll();
  const range = 0.1;
  const bottomOffset = 500;
  const topOffset = -500;
  const ref = useRef();
  const [minHeight, setMinHeight] = useState('auto');
  const [elementTop, setElementTop] = useState(0);
  const springConfig = {
    damping: 50,
    stiffness: 150,
    mass: rand(1, 3),
  };

  const calculateMinHeight = (height) => height + height * range;

  useLayoutEffect(() => {
    if (!ref.current) return {};
    const onResize = () => {
      setElementTop(ref.current.offsetTop);
      setMinHeight(calculateMinHeight(ref.current.offsetHeight, range));
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [ref, range]);

  const y = useSpring(
    useTransform(
      scrollRef || scrollY,
      [elementTop + topOffset, elementTop + bottomOffset],
      ['0%', '50%'],
    ),
    springConfig,
  );

  const renderParallax = () => {
    if (mobile.matches) {
      return <Parallax speed={-7}>{children}</Parallax>;
    }
    return (
      <motion.div ref={ref} initial={{ y: 0 }} style={{ y }}>
        {children}
      </motion.div>
    );
  };

  return (
    <div style={{ minHeight }}>
      {renderParallax()}
    </div>
  );
}

ParallaxContainer.defaultProps = {
  children: null,
  scrollRef: null,
};

ParallaxContainer.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  scrollRef: PropTypes.any,
};

export default ParallaxContainer;
