import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { animateScroll as scroll } from 'react-scroll';
import Button from '../../common/components/Button';
import Project from '../Projects';
// import Products from '../Products';

import {
  ContainerModal,
  CloseButton,
  CloseIcon,
  ContainerControls,
  StepButtonsContainer,
  NextStepButton,
  PreviusStepButton,
  StatusBar,
  Logo,
  ChevronLeft,
  ChevronRight,
  ButtonsContainer,
  ProgressContainer,
  ProgressBar,
  MobileHeader,
  LogoMobile,
} from './style';

function ModalContent({
  data,
  open,
  closeModal,
  // modal,
}) {
  const [changeBackground, setChangeBackground] = useState(false);
  const mobile = window.matchMedia('(max-width: 960px)');
  const horizontalScroll = useRef(null);
  const bar = useRef(null);

  const handleHeaderBackground = (scrollContainer) => {
    if (mobile.matches) {
      if (scrollContainer.scrollTop > 10) {
        setChangeBackground(true);
      } else {
        setChangeBackground(false);
      }
    }
  };

  const sideScroll = (direction) => {
    if (horizontalScroll.current.offsetWidth) {
      const scrollDistance = horizontalScroll.current.offsetWidth;
      scroll.scrollMore(direction === 'left' ? -scrollDistance : scrollDistance, {
        containerId: 'horizontall-scroll',
        horizontal: true,
        duration: 830,
        delay: 1,
        smooth: true,
      });
    }
  };

  const progressBar = (scrollContainer) => {
    const scrollTotal = scrollContainer.scrollLeft;
    const widthWindow = scrollContainer.scrollWidth - scrollContainer.clientWidth;
    const scrollWidth = `${(scrollTotal / widthWindow) * 100}%`;
    bar.current.style.width = scrollWidth;
  };

  const horizontalScrolling = (evt, scrollContainer) => {
    evt.preventDefault();
    scrollContainer.scrollTo({
      left: scrollContainer.scrollLeft + evt.deltaY * 5,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const scrollContainer = horizontalScroll.current;

    scrollContainer.addEventListener('scroll', () => handleHeaderBackground(scrollContainer));

    if (mobile.matches) return undefined;

    scrollContainer.addEventListener('scroll', () => progressBar(scrollContainer));
    scrollContainer.addEventListener('wheel', (evt) => horizontalScrolling(evt, scrollContainer));

    return () => {
      scrollContainer.removeEventListener('scroll', () => progressBar(scrollContainer));
      scrollContainer.removeEventListener('wheel', (evt) => horizontalScrolling(evt, scrollContainer));
    };
  }, []);

  return (
    <ContainerModal
      ref={horizontalScroll}
      open={open}
      id="horizontall-scroll"
    >
      <CloseButton
        onClick={() => closeModal()}
      >
        <CloseIcon />
      </CloseButton>
      <Logo />

      <MobileHeader changeBackground={changeBackground}>
        <LogoMobile src={`/icons/header-logo${changeBackground ? '-white' : ''}.svg`} />
        <Button
          action={() => closeModal()}
          text="Voltar"
          icon={`/icons/chevron-left${changeBackground ? '' : '-white'}.svg`}
          darkMode={changeBackground}
        />
      </MobileHeader>

      <Project data={data} />

      <ContainerControls>
        <ButtonsContainer>
          <Button
            action={() => closeModal()}
            text="Voltar"
            icon="/icons/white-chevron-left.svg"
          />
          <StepButtonsContainer>
            <PreviusStepButton
              onClick={() => sideScroll('left')}
            >
              <ChevronLeft />
            </PreviusStepButton>
            <NextStepButton
              onClick={() => sideScroll('right')}
            >
              <ChevronRight />
            </NextStepButton>
          </StepButtonsContainer>
        </ButtonsContainer>
        <ProgressContainer>
          <ProgressBar id="bar" ref={bar} />
        </ProgressContainer>
        <StatusBar />
      </ContainerControls>
    </ContainerModal>
  );
}

ModalContent.defaultProps = {
  open: false,
  closeModal: () => null,
  // modal: '',
  data: {
    title: '',
    description: '',
    challenges: '',
    technologies: [],
    results: '',
    images: [],
    links: [],
    mainResults: [],
    costumer: {
      name: '',
      logo: '',
    },
    partners: [
      {
        name: '',
        logo: '',
        link: '',
      },
    ],
  },
};

ModalContent.propTypes = {
  open: PropTypes.bool,
  // modal: PropTypes.string,
  closeModal: PropTypes.func,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    challenges: PropTypes.string,
    technologies: PropTypes.arrayOf(PropTypes.string),
    results: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        iconType: PropTypes.string,
        iconCustom: PropTypes.string,
      }),
    ),
    mainResults: PropTypes.arrayOf(PropTypes.string),
    costumer: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
      link: PropTypes.string,
    }),
    partners: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        logo: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
  }),
};

export default ModalContent;
