import Styled from 'styled-components';
import { motion } from 'framer-motion';

export const ButtonContainer = Styled.button`
  background-color: #191a1b;
  border-radius: 50px;
  border: none;
  outline: none;
  color: #fff;
  padding: 12px 18px;
  margin: 6px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.3s;
  z-index: ${({ lightText }) => (lightText ? 'unset' : '110')};

  ${({ darkMode }) => darkMode && 'background-color: #fff; color: #191a1b;'}
`;

export const ButtonBackGround = Styled(motion.div).attrs({
  variants: {
    visible: {
      x: 0,
      transition: {
        duration: 0.4,
      },
    },
    hidden: {
      x: -200,
      transition: {
        duration: 0.2,
      },
    },
  },
})`
  background-color: #1c9dff;
  opacity: 1;
  position: absolute;
  border-radius: 50px;
  width: 110%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
`;

export const Buttontext = Styled.p`
  color: #fff;
  font-weight: ${({ lightText }) => (lightText ? '500' : '600')};
  font-size: ${({ lightText }) => (lightText ? '19px' : 'max(12px, min(calc(4px + .625vw), 16px))')};
  z-index: 1;
  margin: 0;
  transition: 0.3s ease;
  ${({ darkMode }) => darkMode && 'color: #191a1b;'}
`;

export const Icon = Styled.img.attrs({
  alt: 'voltar-icon',
})`
  width: 16px;
  height: 16px;
  z-index: 1;
  margin-right: 12px;
`;
