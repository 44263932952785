import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div).attrs({
  initial: { scale: 0.9, width: 0 },
  animate: { scale: 1, width: '100%' },
  transition: { delay: 0.8, duration: 0.9 },
})`
  transform: scale(0.2);
  margin: 12px 0;
  overflow: hidden;
`;

export const LineContainer = styled(motion.div).attrs({
  variants: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.8,
        delay: 0.8,
      },
    },
  },
})`
  display: flex;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const HightLight = styled.div`
  padding: 6px 12px;
  border-radius: 50px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-right: 6px;
  word-break: keep-all;
  white-space: nowrap;

  @media (max-width: 465px) {
    margin-top: 6px;
  }
`;

export const TitleText = styled(motion.h1).attrs({
  variants: {
    reduce: {
      fontSize: '18px',
      transition: {
        duration: 0.4,
      },
    },
    expand: {
      fontSize: '90px',
      transition: {
        duration: 0.8,
        delay: 0.3,
      },
    },
  },
})`
  font-weight: 500;
  font-size: max(90px, min(calc(-164px + 16.875vw),160px));
  letter-spacing: -.0400em;
  margin: 0px;
  margin-bottom: 24px;
  position: relative;
  white-space: nowrap;
  opacity: ${({ hiddenTitle }) => (hiddenTitle ? '0' : '1')};
  transition: 0.4s ease;

  @media (max-width: 1270px) {
    font-size: ${({ reduceTitle }) => (reduceTitle ? '18px' : 'max(60px, min(calc(-164px + 16.875vw), 160px)) !important;')};
    transition-delay: ${({ reduceTitle }) => (reduceTitle ? '0' : '0.8s')};
    white-space: nowrap;
  }

  @media (max-width: 925px) {
    font-size: max(55px, min(calc(-164px + 16.875vw), 160px)) !important;
    margin-top: 15px;
  }

  @media (max-width: 725px) {
    font-size: max(45px, min(calc(-164px + 16.875vw), 160px)) !important;
  }

  @media (max-width: 585px) {
    font-size: max(35px, min(calc(-164px + 16.875vw), 160px)) !important;
  }

  @media (max-width: 475px) {
    font-size: max(26px, min(calc(-164px + 16.875vw), 160px)) !important;
  }

  @media (max-width: 375px) {
    font-size: max(24px, min(calc(-164px + 16.875vw), 160px)) !important;
  }
`;
