import styled from 'styled-components';
import { motion } from 'framer-motion';
import { DefaultText } from '../../../../common/defaultStyle';

export const FlexVert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FlexHoriz = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MainContainer = styled.section`
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%; */
  background: ${({ consultancyhover, outsourcinghover }) => {
    if (consultancyhover === 'true') return '#946BD7';
    if (outsourcinghover === 'true') return '#D7856B';
    return '#407AEA';
  }};
  transition: 0.7s ease;
  height: 101vh;
  display: flex;
  align-items: center;
  z-index: 10;

  @media (max-width: 960px) {
    position: unset;
    top: unset;
    left: unset;
    height: unset;
  }
`;

export const Container = styled.div`
  width: 100vw;
  margin: 100px 0;
  max-width: 1300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  padding-top: 85px;
  height: 100%;

  @media (max-width: 960px) {
    height: 100%;
    padding: 12px;
    padding-bottom: 0;
  }
`;

export const SectionTitle = styled(FlexVert)`
  padding: 25px  2%;
  max-width: 600px;
`;

export const Title = styled.h2`
  margin: 0 auto;
  margin-bottom: 24px;
  word-break: keep-all;
  white-space: nowrap;
  font-size: ${({ smalltitle }) => (smalltitle ? '38px' : '48px')};
  color: #fff;

  @media (max-width: 600px) {
    text-align: center;
    font-size: ${({ smalltitle }) => (smalltitle ? '24px' : '32px')};
  }
`;

export const TitleSection = styled(motion.h2).attrs({
  variants: {
    visible: {
      marginLeft: '30px',
      padding: '0',
      minWidth: '310px',
      transition: {
        duration: 1,
        type: 'spring',
        damping: 40,
      },
    },
    normal: {
      marginLeft: '0px',
      padding: '0px 10px',
      transition: {
        duration: 1,
      },
    },
    hidden: {
      marginLeft: '-300px',
      padding: '0px 10px',
      transition: {
        duration: 0.8,
      },
    },
  },
})`
  margin: 0 auto;
  margin-bottom: 24px;
  word-break: keep-all;
  white-space: nowrap;
  font-size: ${({ smalltitle }) => (smalltitle ? '38px' : '48px')};
  color: #fff;

  @media (max-width: 1295px) {
    font-size: 28px;
    max-width: 350px;
  }

  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const Text = styled(DefaultText)`
  color: #fff;
  text-align: center;
  margin: 0px;
  font-size: 18px;
  line-height: 32px;
`;

export const Options = styled.div`
  position: relative;
  padding: 0 44px;
  &:after {
    content: '';
    width: 2px;
    height: 400px;
    background: #fff;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(16deg);
  }

  &:nth-child(2) {
    align-self: center;
    margin-right: 140px;
    margin-top: 65px;
    &:after {
      display: none;
    }
  }

  &:last-child {
    &:after {
      left: -100px;
    }
  }
`;

export const OptionsContainer = styled(FlexHoriz)`
  margin-top: 24px;
  margin-left: -90px;
  width: 100%;
  justify-content: space-between;
  height: 500px;

  @media (max-width: 1295px) {
    margin-left: -50px;
  }

  @media (max-width: 960px) {
    display: none;
  }

  div {
    &:last-child {
      &:after {
        content: '';
        width: 2px;
        height: 400px;
        background: #fff;
        color: #fff;
        position: absolute;
        left: -100px;
        top: 0;
        transform: rotate(16deg);
      }
    }
  }
`;

export const ConsultancyContainer = styled(motion.div).attrs({
  variants: {
    visible: {
      width: '650px',
      height: '100%',
      opacity: 1,
      transition: {
        duration: 0.4,
        type: 'spring',
        damping: 45,
      },
    },
    hidden: {
      width: '0',
      height: '0',
      opacity: 0,
      transition: {
        duration: 1,
        damping: 25,
      },
    },
  },
})`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin-top: -35px;
  font-size: 18px;
  line-height: 32px;
  p {
    &:first-child {
      margin-top: 25px;
    }
  }
`;

export const Consultancy = styled(motion.div).attrs({
  variants: {
    visible: {
      transition: {
        duration: 0.7,
      },
    },
    hidden: {
      transition: {
        duration: 0.5,
      },
    },
  },
})`
  margin-left: 51px;
  padding: 0 100px;
  margin: 0 60px;
  min-height: 400px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1085px) {
    padding: 0 75px;
  }
`;

export const OutsourcingContainer = styled(motion.div).attrs({
  variants: {
    visible: {
      width: '80vw',
      height: '100%',
      opacity: 1,
      transition: {
        duration: 0.3,
        type: 'spring',
        damping: 45,
      },
    },
    hidden: {
      width: '0',
      height: '0',
      opacity: 0,
      transition: {
        duration: 1,
        damping: 25,
      },
    },
  },
})`
  display: flex;
  flex-direction: column;
  p {
    &:first-child {
      margin-top: -10px;
    }
  }
`;

export const Outsourcing = styled(motion.div).attrs({
  variants: {
    visible: {
      marginRight: '50px',
      transition: {
        duration: 0.3,
      },
    },
    hidden: {
      transition: {
        duration: 0.2,
      },
    },
  },
})`
  padding: 0 44px;
  position: relative;
  min-height: 400px;

  @media (max-width: 1250px) {
    padding: 0 5px;
  }
`;

export const ConsultancyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    text-align: start;
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;

export const OutsourcingContent = styled(ConsultancyContent)`
  @media (max-width: 1250px) {
    width: 400px;
  }
`;

export const TextAnimated = styled(motion.p).attrs({
  variants: {
    visible: {
      opacity: 1,
      display: 'flex',
      transition: {
        delay: 0.6,
        duration: 0.6,
      },
    },
    hidden: {
      opacity: 0,
      display: 'none',
      transition: {
        duration: 0.2,
      },
    },
  },
})`
  color: #fff;
  font-size: 14px;
  max-width: 550px;
  font-size: 18px;
  line-height: 32px;

  @media (max-width: 1295px) {
    font-size: 13px;
    max-width: 650px;
  }
`;

export const AnimatedTitle = styled(motion.h3).attrs({
  variants: {
    visible: {
      padding: '0',
      minWidth: '210px',
      transition: {
        duration: 0.4,
        type: 'spring',
        damping: 40,
      },
    },
    hidden: {
      marginTop: '210px',
      marginLeft: '-120px',
      padding: '0 10px',
      transition: {
        duration: 0.8,
      },
    },
  },
})`
  word-break: keep-all;
  white-space: nowrap;
  font-size: ${({ smalltitle }) => (smalltitle ? '38px' : '48px')};
  color: #fff;
  font-weight: bold;
  margin-top: 27px;
  margin-bottom: 30px;
  padding: 0 26px;

  @media (max-width: 1295px) {
    font-size: 28px;
    max-width: 350px;
  }
`;

export const OutsourcingTitle = styled(AnimatedTitle).attrs({
  variants: {
    visible: {
      padding: '0',
      transition: {
        duration: 0.4,
        type: 'spring',
        damping: 40,
      },
    },
    hidden: {
      marginTop: '100px',
      padding: '0 10px',
      transition: {
        duration: 0.8,
      },
    },
  },
})`
  padding: 0;
  min-width: 0;

  @media (max-width: 1295px) {
    max-width: 350px;
  }
`;

export const MobileContainer = styled.div``;

export const Software = styled.div``;
