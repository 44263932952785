import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CanvasLogo from '../../components/canvasLogo';
import Title from '../../components/title';
import {
  MainContainer,
  Container,
  CanvasContainer,
  Canvas,
  ImageLogo,
  ContentContainer,
  Separator,
  ScrollContainer,
  ScrollIconContainer,
  ScrollIcon,
  Text,
  ContentAminContainer,
} from './style';
import PresentationMobile from './mobile';

const strings = {
  scroll: 'scroll',
};
function Presentation({
  children,
  onEnd,
  contentScroll,
  planContainer,
  setShowContent,
  showContent,
  setHidden,
  hidden,
}) {
  const smallMobileDevices = window.matchMedia('(max-width: 960px)');

  useEffect(() => {
    if (smallMobileDevices.matches) return;
    contentScroll.current.addEventListener('scroll', () => {
      const {
        offsetHeight,
        scrollTop,
      } = contentScroll.current;

      if ((offsetHeight + scrollTop) + 50 >= planContainer.current.clientHeight) {
        onEnd(true);
        setHidden(true);
      } else {
        setHidden(false);
        onEnd(false);
      }
    });

    document.addEventListener('wheel', (e) => {
      if (window.innterWidth < 960) return;

      if (!showContent && e.deltaY > 0) {
        setShowContent(true);
      } else if (contentScroll.current.scrollTop === 0) {
        setShowContent(false);
        onEnd(false);
      }
    });
  }, []);

  if (window.innerWidth < 960) {
    return (<PresentationMobile contentScroll={contentScroll}>{children}</PresentationMobile>);
  }

  const handleAnimation = () => {
    if (hidden) return 'hidden';
    if (showContent) return 'reduce' || 'as';
    return 'expand';
  };

  return (
    <MainContainer id="maincontainer">
      <Container animate={handleAnimation()} hiddenContainer={hidden}>
        <CanvasContainer animate={showContent ? 'reduce' : 'expand'}>
          <Canvas>
            <CanvasLogo />
          </Canvas>
          <ImageLogo />
        </CanvasContainer>
        <Title reduce={showContent} hidden={hidden} />
      </Container>
      <ContentContainer animate={showContent ? 'expand' : 'reduce'} hidden={hidden}>
        <Separator hiddenSeparator={hidden} />
        <ScrollContainer animate={showContent ? 'hidde' : 'show'}>
          <ScrollIconContainer>
            <ScrollIcon />
          </ScrollIconContainer>
          <Text>{strings.scroll}</Text>
        </ScrollContainer>
        <ContentAminContainer
          ref={contentScroll}
          animate={showContent ? 'show' : 'hidde'}
          id="scroll-container"
          hiddenSeparator={hidden}
        >
          {children}
        </ContentAminContainer>
      </ContentContainer>
    </MainContainer>
  );
}

Presentation.defaultProps = {
  children: null,
  onEnd: () => null,
  contentScroll: null,
  planContainer: null,
  showContent: false,
  hidden: false,
  setShowContent: () => null,
  setHidden: () => null,
};

Presentation.propTypes = {
  children: PropTypes.node,
  onEnd: PropTypes.func,
  setShowContent: PropTypes.func,
  setHidden: PropTypes.func,
  showContent: PropTypes.bool,
  hidden: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  contentScroll: PropTypes.any,
  planContainer: PropTypes.any,
};

export default Presentation;
