import React from 'react';

import {
  MainContainer,
  I9Image,
  CardContent,
  CardIcon,
  Title,
  Description,
  ButtonContainer,
  CustomButton,
} from './style';

function I9TeamsIntegrations() {
  const contactRedirect = () => {
    window.location.href = 'https://forms.office.com/Pages/ResponsePage.aspx?id=4RvYq0R4TUec2ZqRTgFTb7pY9xk6bRlIn_rl0dpdsQhUNUFBTEcyR1czVzNRQTM2Tzk1R1dNQ0VQNiQlQCN0PWcu';
  };

  return (
    <MainContainer>
      <I9Image />
      <CardContent>
        <CardIcon />
        <Title>
          Comunicação fácil e centralizada!
        </Title>
        <Description>
          Com o Microsoft Teams você permite que toda sua empresa esteja em constante comunicação.
          <div style={{ marginTop: '12px' }} />
          Reuniões - chats - chamadas - colaboração
        </Description>
        <ButtonContainer>
          <CustomButton
            darkMode={false}
            action={() => contactRedirect()}
            text="Entre em contato"
            primaryBtn
          />
        </ButtonContainer>
      </CardContent>
    </MainContainer>
  );
}

export default I9TeamsIntegrations;
