import React from 'react';
import {
  Integrations,
  IconContainer,
  Icon,
  Text,
} from './style';

function IntegrationIcons() {
  return (
    <Integrations>
      <IconContainer>
        <Icon src="/icons/whatsapp.png" alt="Whatsapp" />
        <Text>
          Melhore seu atendimento integrando com o Whatsapp para que
          os clientes se conectem com seus agentes e chatbots.
        </Text>
      </IconContainer>
      <IconContainer facebookIcon>
        <Icon src="/icons/facebook.png" alt="Facebook" />
        <Text>
          Com o aplicativo Facebook Messenger, você pode lidar da sua página no facebook
          diretamente.
        </Text>
      </IconContainer>
      <IconContainer>
        <Icon src="/icons/instagram.png" alt="Instagram" />
        <Text>
          Com a integração com o Instagram, você pode lidar com mensagens de
          seus seguidores bem mais eficaz.
        </Text>
      </IconContainer>
      {/* <IconContainer>
        <Icon src="/icons/gmail.png" alt="Gmail" />
        <Text>
          Com o aplicativo Facebook Messenger, você pode lidar com mensagens de
          seus contatos do Facebook diretamente.
        </Text>
      </IconContainer> */}
    </Integrations>
  );
}

export default IntegrationIcons;
