import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UpAnimContainer } from '../../common/theme';
import Button from '../../common/components/Button';

import {
  ContainerAdmin,
  HeaderContainer,
  Logo,
  HeaderContent,
  ContactButtons,
} from './style';

function ProductsHeader({
  // handleOpen,
  // handleClose,
  open,
  // section,
  darkMode,
  onChangeSection,
//   showLinks,
//   projectsLink,
//   stackLink,
//   whatWeDoLink,
}) {
  const [scrolled, setScrolled] = useState(false);

  const contactRedirect = () => {
    window.location.href = 'https://forms.office.com/Pages/ResponsePage.aspx?id=4RvYq0R4TUec2ZqRTgFTb7pY9xk6bRlIn_rl0dpdsQhUNUFBTEcyR1czVzNRQTM2Tzk1R1dNQ0VQNiQlQCN0PWcu';
  };

  const handleHeaderScroll = () => {
    if (window.scrollY > 10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => handleHeaderScroll());

    return () => {
      window.removeEventListener('scroll', () => handleHeaderScroll());
    };
  }, []);

  return (
    <ContainerAdmin darkMode={darkMode} scrolled={scrolled}>
      <HeaderContainer>
        <HeaderContent>
          <UpAnimContainer>
            {window.innerWidth < 960 ? (
              <Logo
                src={`/icons/header-logo${!open && darkMode ? '-white' : ''}.svg`}
                animate={darkMode ? 'dark' : 'light'}
                onClick={() => onChangeSection('plan')}
              />
            ) : (
              <Logo
                src={`/icons/header-logo${darkMode ? '-white' : ''}.svg`}
                animate={darkMode ? 'dark' : 'light'}
                onClick={() => onChangeSection('plan')}
              />
            )}
          </UpAnimContainer>
          <ContactButtons>
            {/* {window.innerWidth > 960 && (
              <Links showLinks={showLinks}>
                <LinkButton
                  darkMode={darkMode && !open}
                  text="O que fazemos"
                  style={{ marginLeft: '22px' }}
                  active={whatWeDoLink}
                  action={() => onChangeSection('whatwedo')}
                />
                <LinkButton
                  darkMode={darkMode && !open}
                  text="Clientes"
                  style={{ marginLeft: '22px' }}
                  active={projectsLink}
                  action={() => onChangeSection('projects')}
                  sectionName="projects"
                />
                <LinkButton
                  darkMode={darkMode && !open}
                  text="Nossa Stack"
                  style={{ marginLeft: '22px' }}
                  active={stackLink}
                  action={() => onChangeSection('stack')}
                />
              </Links>
            )} */}

            <Button
              darkMode={darkMode && !open}
              action={() => contactRedirect()}
              text="Entre em contato"
              primaryBtn
            />
          </ContactButtons>
        </HeaderContent>
        {/* <Separator darkMode={darkMode} /> */}
      </HeaderContainer>
    </ContainerAdmin>
  );
}

ProductsHeader.defaultProps = {
  // handleOpen: () => null,
  // handleClose: () => null,
  onChangeSection: () => null,
  darkMode: false,
  open: false,
};

ProductsHeader.propTypes = {
  // handleOpen: PropTypes.func,
  // handleClose: PropTypes.func,
  onChangeSection: PropTypes.func,
  darkMode: PropTypes.bool,
  open: PropTypes.bool,
};

export default ProductsHeader;
