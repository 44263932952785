import styled from 'styled-components';
import Button from '../../../../common/components/Button';

export const Container = styled.div`
  width: 100%;
  padding: 100px 2%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  background-color: #000000;
  margin-top: 60px;
  /* overflow: hidden; */

  @media (max-width: 1080px) {
    align-items: flex-end;
    flex-direction: column;
  }

  @media (max-width: 780px) {
    align-items: center;
    margin-top: 0;
    height: 100vh;
  }

  @media (max-width: 475px) {
    height: 100%;
  }
`;

export const SubTitle = styled.span`
  color: #000;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
`;

export const Icon = styled.img``;

export const IconContainer = styled.div`
  max-width: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Text = styled.p`
  color: #fff;
  margin-top: 10px;
  width: 100%;
  font-size: 18px;
  line-height: 35px;

  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 18px;
    line-height: 32px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media (max-width: 1085px) {
    gap: 30px;
  }
`;

export const AboutContent = styled.div`
  max-width: 800px;
  position: relative;
  margin-top: ${(props) => (props.mt ? '30px' : '0')};

  @media (max-width: 1080px) {
    align-self: flex-start;
  }

  @media (max-width: 1080px) {
    margin-bottom: 30px;
  }
`;

export const Title = styled.h1`
  color: #000;
  font-size: 44px;
  font-weight: 500;
  color: #fff;

  @media (max-width: 1420px) {
    font-size: 42px;
  }

  @media (max-width: 1080px) {
    font-size: 34px;
  }

  @media (max-width: 525px) {
    font-size: 30px;
  }
`;

export const Image = styled.img.attrs({
  src: '/images/cart-money.png',
  alt: 'Services',
})`
  max-width: 100%;

  @media (max-width: 1440px) {
    max-width: 100%;
  }

  @media (max-width: 1080px) {
    min-width: unset;
    justify-self: flex-end;
    max-width: 680px;
    padding-left: 0;
    position: relative;
    right: -100px;
  }

  @media (max-width: 780px) {
    position: unset;
    max-width: 600px;
    width: 100%;
    justify-self: center;
    right: 0;
  }

  /* @media (max-width: 620px) {
    max-width: 590px;
  }

  @media (max-width: 525px) {
    max-width: 500px;
  }

  @media (max-width: 415px) {
    max-width: 470px;
  }

  @media (max-width: 375px) {
    max-width: 430px;
  }

  @media (max-width: 330px) {
    width: 100%;
  } */

  @media (max-width: 1085px) {
    display: none;
  }
`;

export const CustomButton = styled(Button)`
  max-width: 200px;
  font-weight: 600;
  font-size: 16px;
`;

export const MicrosoftImage = styled.img`
  max-width: 266px;
  width: 100%;
  height: auto;
`;

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const PaymentImages = styled.img`
  max-width: 100%;
  height: auto;
`;

export const LimitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1085px) {
    flex-direction: column;
    padding: 2%;
  }
`;
