import styled from 'styled-components';

export const Container = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    display: flex;
  }
`;

export const SoftwareDev = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 12px;
  margin-top: -35px;
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 24px;
  text-align: center;
`;

export const Text = styled.p`
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 32px;
`;

export const Image = styled.img.attrs({
  src: '/icons/objectives.svg',
  alt: 'objectives',
})`
  max-width: 100%;
  height: 180px;
  margin-top: 24px;
`;

export const Consultancy = styled(SoftwareDev)`
  height: 100vh;
  background-color: #946BD7;
  margin: 0 -12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  border-top: solid 1px rgba(255, 255, 255, 0.3);
`;

export const Outsourcing = styled(Consultancy)`
  background-color: #D7856B;
  border-top: none;
`;

export const LineContainer = styled.div`
  display: flex;
`;
