import React from 'react';
import {
  Integrations,
  IconContainer,
  Icon,
  Text,
  LimitContainer,
} from './style';

function IntegrationIcons() {
  return (
    <Integrations>
      <LimitContainer>
        <IconContainer>
          <Icon src="/images/teams.png" alt="Teams" />
          <Text>
            Tudo o que você precisa para mensagens, conferências e
            compartilhamento de arquivos.
          </Text>
        </IconContainer>
        <IconContainer>
          <Icon src="/images/pp.png" alt="PowerPoint" />
          <Text>
            Trabalhe com outras pessoas de forma colaborativa.
            Aumentando a produtividade da sua equipe.
          </Text>
        </IconContainer>
        <IconContainer>
          <Icon src="/images/excel.png" alt="Teams" />
          <Text>
            Organize os dados de maneira fácil de navegar.
            Analise e faça previsões com seus dados de forma eficiente.
          </Text>
        </IconContainer>
        {/* <IconContainer>
          <Icon src="/icons/gmail.png" alt="Gmail" />
          <Text>
            Com o aplicativo Facebook Messenger, você pode lidar com mensagens de
            seus contatos do Facebook diretamente.
          </Text>
        </IconContainer> */}
      </LimitContainer>
    </Integrations>
  );
}

export default IntegrationIcons;
