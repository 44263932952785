import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.section).attrs({
  variants: {
    visible: {
      width: '90vw',
      paddingLeft: '30px',
      opacity: 1,
      transition: {
        duration: 1.5,
        type: 'spring',
      },
    },
    hidden: {
      width: '0',
      opacity: 0,
      transition: {
        duration: 1.5,
        delay: 0.5,
        damping: 25,
      },
    },
  },
})`
  opacity: 1;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  margin-top: -35px;
`;

export const Card = styled(motion.div).attrs({
  variants: {
    visible: {
      display: 'block',
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.4,
      },
    },
    hidden: {
      display: 'none',
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  },
})`
  background: #fff;
  border-radius: 18px;
  padding: 0 2%;
  margin-top: 25px;
  width: 550px;
  height: 200px;

  @media (max-width: 1250px){
    width: 400px;
    height: 180px;
  }
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled(motion.p).attrs({
  variants: {
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  },
})`
  color: #fff;
  font-size: 16px;
  line-height: 32px;
`;

export const CardText = styled(motion.p).attrs({
  variants: {
    visible: {
      opacity: 1,
      transition: {
        delay: 1,
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  },
})`
  color: #fff;
  line-height: 30px;
  max-width: 600px;
  font-size: 16px;
  line-height: 32px;

  
  @media (max-width: 1250px){
    font-size: 12px;
    line-height: 25px;
  }
`;

export const Title = styled(motion.p).attrs({
  variants: {
    visible: {
      opacity: 1,
      transition: {
        delay: 0.8,
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  },
})`
  color: #fff;
  font-size: 24px;
  font-weight: 700;
`;

export const Image = styled(motion.img).attrs({
  src: '/icons/objectives.svg',
  alt: 'objectives',
  variants: {
    visible: {
      opacity: 1,
      transition: {
        delay: 0.8,
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  },
})`
  max-width: 100%;
  height: 180px;
  margin-left: 50px;
  margin-right: 20px;
  margin-top: 85px;

  @media (max-width: 1250px){
    height: 130px;
    margin-left: -60px;
    margin-top: 100px;
  }
`;
