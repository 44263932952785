import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import { Button } from './style';

function CustomLink({
  style,
  text,
  action,
  darkMode,
  active,
  sectionName,
}) {
  return (
    <Link to={sectionName}>
      <Button
        style={style}
        onClick={action}
        active={active}
        darkMode={darkMode}
      >
        {text}
      </Button>
    </Link>
  );
}

CustomLink.defaultProps = {
  text: '',
  sectionName: '',
  style: {},
  action: () => null,
  darkMode: false,
  active: false,
};

CustomLink.propTypes = {
  sectionName: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.shape({ root: PropTypes.string }),
  action: PropTypes.func,
  darkMode: PropTypes.bool,
  active: PropTypes.bool,
};

export default CustomLink;
