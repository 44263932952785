import React from 'react';
import PropTypes from 'prop-types';

import {
  FirstSection,
  InfoContainer,
  Title,
  SubTitle,
  CostumerLogo,
  DescriptionContainer,
  SecondSection,
  GaleryContainer,
  Projectimage,
  LinkContainer,
  LinkIcon,
  PartnerContainer,
  PartnerItem,
  PartnerLogo,
  PartnerName,
  Text,
  GalleryTitle,
  DescriptionSection,
  TechInfoContainer,
  TechInfoText,
  About,
  DescriptionContent,
} from './style';

const strings = {
  back: 'Voltar',
  results: 'Resultados obtidos',
  imagesAlt: 'Imagem do projeto',
};

const iconImages = {
  icons: {
    site: '/icons/site.svg',
    twitter: '/icons/twitter.svg',
    instagram: '/icons/instagram.svg',
    facebook: '/icons/face.svg',
  },
};

function Project({ data }) {
  const {
    mainResults,
    challenges,
    technologies,
    results,
  } = data;

  const handleIcon = (iconName) => {
    if (!iconName) {
      return iconImages.icons.site;
    }

    const iconsArray = {
      site: iconImages.icons.site,
      instagram: iconImages.icons.instagram,
      twitter: iconImages.icons.twitter,
      facebook: iconImages.icons.facebook,
    };

    return iconsArray[iconName];
  };

  const urlMask = (url) => {
    if (url) {
      return url.replace(/https?:\/\/(www\.)?/gi, '');
    }
    return null;
  };

  const renderLinks = () => {
    if (!Array.isArray(data.links)) return null;

    return data.links.map((linkData) => (
      <LinkContainer key={linkData.link} href={linkData.link} target="_blank">
        <LinkIcon
          src={handleIcon(linkData.iconType)}
          type={linkData.iconType}
          custom={linkData.iconCustom}
        />
        {linkData.name || urlMask(linkData.link)}
      </LinkContainer>
    ));
  };

  const renderPartners = () => {
    if (!Array.isArray(data.partners)) return null;

    return (
      <PartnerContainer>
        {data.partners.map((partner) => (
          <PartnerItem>
            {partner.logo && (
              <PartnerLogo src={partner.logo} alt={partner.name} />
            )}
            <PartnerName>{partner.name}</PartnerName>
          </PartnerItem>
        ))}
      </PartnerContainer>
    );
  };

  const renderTecnologies = () => {
    if (!Array.isArray(technologies)) return null;

    return technologies.map((technology) => (
      <TechInfoText key={technology}>{technology}</TechInfoText>
    ));
  };

  return (
    <>
      <FirstSection>
        <InfoContainer>
          <Title>{data.title}</Title>
          <SubTitle>{data.costumer.name}</SubTitle>
          {data.costumer.logo && (
            <CostumerLogo
              src={data.costumer.logo}
              alt={data.costumer.name}
            />
          )}

          <TechInfoContainer>{renderTecnologies()}</TechInfoContainer>

          {renderLinks()}
          {renderPartners()}
        </InfoContainer>
      </FirstSection>

      <DescriptionSection>
        <DescriptionContainer>
          <About>
            <SubTitle descriptionTitle>Sobre</SubTitle>
            <Text>{data.description}</Text>
          </About>

          {data.partners && (
            <DescriptionContent partners>
              <SubTitle descriptionTitle>Clientes / Parceiros</SubTitle>
              <GaleryContainer>
                {data.partners.map((images) => (
                  <Projectimage src={images} alt={strings.imagesAlt} />
                ))}
              </GaleryContainer>
            </DescriptionContent>
          )}
        </DescriptionContainer>
      </DescriptionSection>

      <DescriptionSection>
        <DescriptionContainer>
          <DescriptionContent>
            <SubTitle descriptionTitle>Desafios</SubTitle>
            <Text>{challenges}</Text>
            <SubTitle descriptionTitle>Resultados do nosso trabalho</SubTitle>
            <Text>{results}</Text>
          </DescriptionContent>
        </DescriptionContainer>
      </DescriptionSection>

      <SecondSection>
        {mainResults && (
          <>
            <GalleryTitle>
              Resultados
              <br />
              <span>obtidos</span>
            </GalleryTitle>
            <GaleryContainer>
              {mainResults.map((images) => (
                <Projectimage src={images} alt={strings.imagesAlt} limitedImage={data.title === 'Power Platform' || data.limitedImage} />
              ))}
            </GaleryContainer>
          </>
        )}
      </SecondSection>
    </>
  );
}

Project.defaultProps = {
  data: {
    title: '',
    description: '',
    challenges: '',
    technologies: [],
    results: '',
    images: [],
    limitedImage: false,
    links: [],
    mainResults: [],
    costumer: {
      name: '',
      logo: '',
    },
    partners: [
      {
        name: '',
        logo: '',
        link: '',
      },
    ],
  },
};

Project.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    limitedImage: PropTypes.bool,
    challenges: PropTypes.string,
    technologies: PropTypes.arrayOf(PropTypes.string),
    results: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        iconType: PropTypes.string,
        iconCustom: PropTypes.string,
      }),
    ),
    mainResults: PropTypes.arrayOf(PropTypes.string),
    costumer: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
      link: PropTypes.string,
    }),
    partners: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        logo: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
  }),
};

export default Project;
