import React from 'react';

import {
  Container,
  SoftwareDev,
  Title,
  Text,
  Image,
  Consultancy,
  Outsourcing,
} from './style';

const strings = {
  squadText: 'Usamos as mesmas técnicas de criação de produtos que as grandes empresas de tecnologia usam. Oferecemos toda a base para entregar a solução tecnológica que o seu cliente necessita.',
  consultancyText:
    'Utilizamos as melhores práticas das metodologias ágeis (Scrum, XP e Lean) que auxiliam na organização de grandes entregas de forma que sejam rápidas e agreguem valor para a empresa de forma iterativa e incremental. Nossa equipe altamente experiente sabe onde acontece verdadeiramente o impacto da adoção de uma nova metodologia dentro de uma empresa. Por isso nosso diferencial de atuação é entrar no cerne da questão de adoção do ágil na cultura do cliente.',
  outsourcingText:
    'Com a finalidade de facilitar processos e alavancar a capacidade de produção do cliente, oferecemos o serviço de Outsourcing de TI com foco em recursos humanos. Nossa equipe é formada por profissionais de TI especializados em diversas frentes técnicas e de negócio, agregamos conhecimentos em áreas do cliente sem as burocracias de uma contratação interna. Podemos trabalhar em modelo de horas abertas ou pacote de trabalho fechado por projeto.',
};

function MobileContainer() {
  return (
    <Container>
      <SoftwareDev>
        <Title>Squad as a Service</Title>
        <Text>
          {strings.squadText}
        </Text>

        <Image />
      </SoftwareDev>
      <Consultancy>
        <Title>Consultoria Ágil</Title>
        <Text>
          {strings.consultancyText}
        </Text>
      </Consultancy>
      <Outsourcing>
        <Title>Outsourcing</Title>
        <Text style={{ marginTop: '5px' }}>
          {strings.outsourcingText}
        </Text>
      </Outsourcing>
    </Container>
  );
}

export default MobileContainer;
