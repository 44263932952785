import styled from 'styled-components';

export const MainContainer = styled.section`
  background-color: #f2f2f2;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  padding-top: 150px;

  @media (max-width: 960px) {
    position: unset;
    top: unset;
    left: unset;
    height: unset;
    padding: 100px 0 100px 0;
    padding-top: ${({ showImages }) => (showImages === 'products' && '0')};
  }
`;

export const CarroselContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  @media (max-width: 960px) {
    display: none;
  }

  .box[id="0"]{
    background: url("images/gecov.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .box[id="1"]{
    background: url("images/ms365.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .box[id="2"]{
    background: url("images/neo.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .box[id="3"]{
    background: url("images/cryptocase.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .box[id="4"]{
    background: url("images/classeacase.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .box[id="5"]{
    background: url("images/socialcase.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }


  .box-products,
  .box {
    width: 1024px;
    height: 768px;
    transform: translateZ(0);
    outline: 1px solid transparent;
    background: red;
  }

  div,
  img {
    position: absolute;
  }
`;

export const Title = styled.h2`
  color: #000;
  font-size: 48px;
  text-align: center;
  padding: 0 3%;
  margin-top: 50px;
  margin-bottom: 80px;

  @media (max-width: 960px) {
    margin-top: unset;
    margin-bottom: -40px;
    font-size: 32px;
  }
`;

export const Dragger = styled.div`
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  user-select: none;
  position: absolute;

  top: 0;
  left: 0;
  display: none;

  @media (max-width: 960px) {
    display: block;
  }
`;

export const Container = styled.div`
  position: relative;
  margin-top: -105px;
  @media (max-width: 960px) {
    margin-top: unset;
    padding: 2%;
    white-space: nowrap;
    overflow-x: auto;
    margin-top: 65px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const MobileBox = styled.div`
  background: ${({ imagePath }) => (imagePath && `url(${imagePath})`)};
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: none;
  @media (max-width: 960px) {
    display: block;
    width: 550px;
    height: 400px;
    margin: 10px;
    display: inline-block;
    border-radius: 16px;
    transform: scaleX(-1);
  }

  @media (max-width: 625px) {
    width: 400px;
    height: 300px;
  }

  @media (max-width: 475px) {
    width: 330px;
    height: 250px;
  }

  @media (max-width: 420px) {
    width: 310px;
    height: 230px;
  }

  @media (max-width: 355px) {
    width: 285px;
    height: 220px;
  }
`;

export const Products = styled.div``;

export const ClientLogo = styled.img`
  max-width: 130px;
  max-height: 90px;
  object-fit: contain;

  @media (max-width: 960px) {
    max-width: 80px;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 960px;
  width: 100%;
  justify-content: center;
  gap: 34px;
  margin: 0 auto;
  margin-top: 220px;
  padding: 0 6px;

  @media (max-width: 960px) {
    margin-top: 0;
  }
`;
