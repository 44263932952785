import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FadeAnimContainer } from '../../../../common/theme';

function CanvasLogo({ mobile }) {
  const canvasRef = useRef();

  const setcanvas = () => {
    const canvas = canvasRef.current;

    const sizes = {
      width: mobile ? window.innerWidth : window.innerWidth / 3.5,
      height: window.innerHeight / 3.5,
    };
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xf2f2f2);
    const gltfLoader = new GLTFLoader();

    gltfLoader.load(
      'images/logo3d.gltf',
      (gltf) => {
        const model = gltf.scene;

        model.scale.set(0.025, 0.025, 0.025);
        scene.add(model);
      },
    );

    const mesh = new THREE.Mesh(
      new THREE.BoxGeometry(1, 1, 1, 5, 5, 5),
      new THREE.MeshBasicMaterial({ color: 0xfff }),
    );

    // Camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height);
    camera.position.x = 2;
    camera.position.y = 2;
    camera.position.z = 2;
    camera.lookAt(mesh.position);
    scene.add(camera);

    const mousePosition = { x: 0, y: 0 };

    window.addEventListener('mousemove', (e) => {
      mousePosition.x = (e.clientX / sizes.width) * 0.02 + 0.009;
      mousePosition.y = (e.clientY / sizes.height) * -0.08 + 0.009;
    });

    const renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const tick = () => {
      camera.position.x = (Math.sin(mousePosition.x * Math.PI * 2) * 3) - 0.4;
      camera.position.z = Math.cos(mousePosition.x * Math.PI * 2) * 2.6;
      camera.position.y = Math.sin(mousePosition.y * Math.PI * 2) * -0.4;
      camera.lookAt(mesh.position);

      // Render
      renderer.render(scene, camera);

      // Call tick again on the next frame
      window.requestAnimationFrame(tick);
    };

    tick();
  };

  useEffect(() => {
    setcanvas();
  }, []);

  return (
    <FadeAnimContainer>
      <canvas ref={canvasRef} />
    </FadeAnimContainer>
  );
}

CanvasLogo.defaultProps = {
  mobile: false,
};

CanvasLogo.propTypes = {
  mobile: PropTypes.bool,
};

export default CanvasLogo;
