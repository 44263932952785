import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { UpAnimContainer } from '../../common/theme';

export const ContainerAdmin = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  background: ${({ darkMode }) => (darkMode ? 'transparent' : '#f2f2f2')};
  transition: 0.3s ease;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  pointer-events: ${({ showLinks }) => (showLinks ? 'unset' : 'none')};
  opacity: ${({ showLinks }) => (showLinks ? '1' : '0')};
  transition: 0.7s ease;

  .active {
    background: green;
  }
`;

export const HeaderContainer = styled(motion.header).attrs({
  animate: { paddingTop: '20px', paddingBottom: '0' },
  transition: { delay: 0.5, duration: 0.5 },
})`
  padding: 40px 2%;
`;

export const Separator = styled(motion.div).attrs({
  animate: { width: '100%' },
  transition: { delay: 0.5, duration: 0.6 },
})`
  margin-top: 14px;
  background-color: #c8c8c8;
  height: 0.8px;
  width: 0;
  background: ${({ darkMode }) => (darkMode ? 'transparent' : '#c8c8c8')};
`;

export const LinkPlatform = styled(Link)`
  text-decoration: none;
  margin-right: 20px;
  margin-left: 20px;
  position: relative;
  top: -2px;
  color: #000;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContactButtons = styled(UpAnimContainer)`
  z-index: 1;
  display: flex;
  align-items: center;
`;

export const Logo = styled(motion.img).attrs({
  alt: 'objectives',
  variants: {
    dark: {
      opacity: [0, 1],
      delay: 0.2,
      duration: 0.2,
    },
    light: {
      opacity: [0, 1],
      delay: 0.2,
      duration: 0.2,
    },
  },
})`
  max-width: 100px;
  object-fit: cover;
  cursor: pointer;
`;
