import React from 'react';
import PropTypes from 'prop-types';
import AboutMicrosoft from './componentes/aboutMicrosoft';
import ExampleImage from './componentes/landingImage';
import LandingMessage from './componentes/landingText';
import { Container, LandingExample, LandingContent } from './style';
import AboutPlatform from './componentes/aboutPlatform';
import AboutPayment from './componentes/aboutPayment';
import IntegrationIcons from './componentes/integrations';
import I9ExcelIntegration from './componentes/I9ExcelIntegration';
import I9TeamsIntegration from './componentes/I9TeamsIntegration';

function Products({ footerRef, paymentRef, handleOpen }) {
  return (
    <Container>
      <LandingContent>
        <LandingExample>
          <LandingMessage />
          <ExampleImage />
        </LandingExample>
        <IntegrationIcons />
      </LandingContent>
      <AboutPlatform />
      <AboutMicrosoft footerRef={footerRef} handleOpen={handleOpen} />
      <I9ExcelIntegration handleOpen={handleOpen} />
      <AboutPayment paymentRef={paymentRef} handleOpen={handleOpen} />
      <I9TeamsIntegration handleOpen={handleOpen} />
    </Container>
  );
}

Products.defaultProps = {
  footerRef: null,
  handleOpen: () => null,
  paymentRef: null,
};

Products.propTypes = {
  footerRef: PropTypes.any,
  paymentRef: PropTypes.any,
  handleOpen: PropTypes.func,
};

export default Products;
