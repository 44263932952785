// eslint-disable-next-line import/prefer-default-export
export const costumerCases = [
  {
    id: 0,
    title: 'GECOV',
    description: 'A plataforma desenvolvida em parceria com a ArqDigital,  fornece às Entidades Credoras (Itaú, Bando do Brasil, Caixa, Bradesco, Santander...), o acesso ao GECOV (Gerenciador de Contrato de Veículo), junto aos Departamentos de Trânsito. Dentre eles: Amazonas, Minas Gerais, Pará, Paraná, Paraíba, Pernambuco, Piauí, Santa Catarina, São Paulo e Rio de Janeiro (DETRAN-AM, DETRAN-MG, DETRAN-PA, DETRAN-PR, DETRAN-PB, DETRAN-PE, DETRAN-PI, DETRAN-SC, DETRAN-SP E DETRAN-RJ).',
    technologies: ['Laserfiche', 'ASP.NET', 'C#', 'Workflow', 'Foundation', 'SharePoint', 'Web Service', 'SQL Server', 'Java Script', 'Bootstrap'],
    challenges: 'Segurança; Criptografia; Performance; Escala; Orquestração de serviços; Regra de negócios',
    results: 'O rápido desenvolvimento da solução, atendendo as exigências do edital, permitiu que a ArqDigital ganhasse a licitação nos primeiros estados do Amazonas e Pará.',
    partners: ['/images/geocov-costumers.jpg'],
    mainResults: ['/images/geocov-sys.png'],
    links: [
      {
        link: 'http://www.gecov.com.br/autenticacao?0',
        iconType: 'site',
      },
    ],
    costumer: {
      name: 'Registro de Contratos | Arqdigital',
      logo: '',
    },
  },
  {
    id: 1,
    title: 'Power Platform',
    description: 'Com o conjunto de aplicativos do Microsoft 365, você sempre fica conectado e aumenta a sua produtividade. Ele garante que você e sua empresa tenham acesso a recursos de alta performance e armazenamento em nuvem que mudará definitivamente a dinâmica do seu dia a dia.\nCom os recursos do Power Platform, a sua empresa conseguirá tomar decisões comerciais embasadas e com confiança, disponibilizando insights orientados por dados para todos. Também será possível criar aplicativos personalizados, automatizar processos organizacionais e criar chatbots para interagir com seus clientes e funcionários.',
    technologies: ['Microsoft Teams', 'Sharepoint', 'Power BI', 'Power Apps', 'Power Automate', 'Power Virtual Agents', 'Microsoft 365'],
    challenges: 'Transformação de requisitos em soluções; Mineração de dados; Segurança; Performance; Escala; Orquestração de serviços; Regra de negócios',
    results: 'Nós levamos nossos clientes a altos níveis de produtividade e colaboração, utilizando a plataforma Microsoft. Fazemos isto com consultorias pontuais e projetos de implantação de toda plataforma para aumentar a produtividade melhorar a interação entre atores assim como o nível de poder de decisão.',
    mainResults: [
      '/images/ms365-result.png',
    ],
    partners: ['/images/ms365-costumers.png'],
    links: [
      {
        link: 'https://powerplatform.microsoft.com/pt-br/',
        iconType: 'site',
      },
    ],
    costumer: {
      logo: '',
    },
  },
  {
    id: 2,
    title: 'Plataforma GIG',
    description: 'A plataforma desenvolvida para a Neocom, para agilizar e automatizar a elaboração de estudos de mercado de grupos como Walmart, FIAT, GM, Toyota e Natura. A solução integra dados do IBGE, clientes da rede do cliente e concorrentes. Aplicando estatística e padrões de estudo de mercado, são gerados relatórios bilíngues para tomada de decisão da diretoria da Companhia.',
    technologies: ['ArcGIS', 'Google Maps', 'ASP.NET', 'C#', 'Workflow', 'Foundation', 'SharePoint', 'Web Service', 'SQL Server', 'Java Script', 'Bootstrap'],
    challenges: 'Mineração de dados; Segurança; Performance; Escala; Orquestração de serviços; Regra de negócios',
    results: 'Um estudo de mercado leva em média de 30 à 45 dias para ser realizado, mas com o uso da plataforma este tempo passou a ser de 5 minutos em média. Com isto foram gerados mais de 1k estudos mercados em um único ano.',
    mainResults: [
      'https://neocom.info/assets/gig.png',
    ],
    partners: ['/images/gig-costumers.jpg'],
    links: [
      {
        link: 'https://neocom.info/gig',
        iconType: 'site',
      },
    ],
    costumer: {
      logo: '',
    },
  },
  {
    id: 3,
    title: 'Crypto Investidor',
    description: 'Foram desenvolvidos para a Crypto Investidor uma ferramenta poderosa para a análise de mercado, permitindo que especialistas acompanhem de perto o mercado de criptomoedas, identificando as tendências e oportunidades mais promissoras ',
    technologies: ['React Native', 'Wordpress', 'Java Script', 'React', 'Firebase', 'Expo', 'Firebase Cloud Messaging', 'Firebase Firestore', 'Pagarme', 'Dotnet 6', 'Azure', 'Docker', 'UX Design', 'UI Design'],
    challenges: 'Mineração de dados em blockchain; Segurança; Performance; Escala; Orquestração de serviços; Regra de negócios',
    results: 'Com a entrega dos produtos digitais feitos para a Crypto Investidor, foi possível observar melhorias no fluxo de ambos os produtos fazendo com que a retenção de usuários e captação de leads crescem.',
    limitedImage: true,
    mainResults: [
      '/images/crypto-result.png',
    ],
    partners: ['/images/crypto-costumers.png'],
    links: [
      {
        link: 'https://cryptoinvestidor.com',
        iconType: 'site',
      },
    ],
    costumer: {
      logo: '',
    },
  },
  {
    id: 4,
    title: 'Colégio Classe A',
    description: 'Com a necessidade de uma atualização visual e estrutural em seu site, foi produzido para o Colégio Classe A um site que com qual cumprisse as necessidades dos usuarios, com uso facilitado, melhorias visuais e de fluxo.',
    technologies: ['Wordpress', 'Figma', 'UX Design', 'UI Design'],
    challenges: 'Redesign do site; Atualização de formularios',
    results: 'Após o redesign de todo o site assim também como na estrutura e alterações no fluxo dos formulários. O produto tornou-se facilmente utilizável pelo seu público, não havendo dúvidas do que fazer e onde encontrar o que se procura.',
    limitedImage: true,
    mainResults: [
      '/images/classea-result.png',
    ],
    partners: ['/images/classea-costumers.png'],
    links: [
      {
        link: 'https://colegioclassea.com.br',
        iconType: 'site',
      },
    ],
    costumer: {
      logo: '',
    },
  },
  {
    id: 5,
    title: 'Social Business',
    description: 'Uma empresa com o foco de ajudar os outros, melhorar o mundo e a vida das pessoas. Essa é a Social Business que através de seus produtos utiliza parte do valor como doações para ONGs.',
    technologies: ['Nuvemshop', 'UX Design', 'UI Design'],
    challenges: 'Produção do ecommerce; Comunicação visual',
    results: 'Com a proposta de ser um e-commerce diferente dos demais, conseguimos cumprir tal objetivo com a proposta visual do e-commerce assim também com todo o seu planejamento e organização mostrada ao público.',
    limitedImage: true,
    mainResults: [
      '/images/social-result.png',
    ],
    partners: ['/images/social-costumers.png'],
    links: [
      {
        link: '',
        iconType: 'site',
      },
    ],
    costumer: {
      logo: '',
    },
  },
];

export const products = [
  {
    id: 0,
    title: 'Produto 1',
    description: 'A plataforma desenvolvida em parceria com a ArqDigital,  fornece às Entidades Credoras (Itaú, Bando do Brasil, Caixa, Bradesco, Santander...), o acesso ao GECOV (Gerenciador de Contrato de Veículo), junto aos Departamentos de Trânsito. Dentre eles: Amazonas, Minas Gerais, Pará, Paraná, Paraíba, Pernambuco, Piauí, Santa Catarina, São Paulo e Rio de Janeiro (DETRAN-AM, DETRAN-MG, DETRAN-PA, DETRAN-PR, DETRAN-PB, DETRAN-PE, DETRAN-PI, DETRAN-SC, DETRAN-SP E DETRAN-RJ).',
    technologies: ['Laserfiche', 'ASP.NET', 'C#', 'Workflow', 'Foundation', 'SharePoint', 'Web Service', 'SQL Server', 'Java Script', 'Bootstrap'],
    challenges: 'Segurança; Criptografia; Performance; Escala; Orquestração de serviços; Regra de negócios',
    results: 'O rápido desenvolvimento da solução, atendendo as exigências do edital, permitiu que a ArqDigital ganhasse a licitação nos primeiros estados do Amazonas e Pará.',
    partners: ['/images/geocov-costumers.jpg'],
    mainResults: ['/images/geocov-sys.png'],
    links: [
      {
        link: 'http://www.gecov.com.br/autenticacao?0',
        iconType: 'site',
      },
    ],
    costumer: {
      name: 'Registro de Contratos | Arqdigital,',
      logo: 'http://www.gecov.com.br/resources/img/logo.png',
    },
  },
  {
    id: 1,
    title: 'Microsoft 365',
    description: 'A plataforma desenvolvida para a Neocom, para agilizar e automatizar a elaboração de estudos de mercado de grupos como Walmart, FIAT, GM, Toyota e Natura. A solução integra dados do IBGE, clientes da rede do cliente e concorrentes. Aplicando estatística e padrões de estudo de mercado, são gerados relatórios bilíngues para tomada de decisão da diretoria da Companhia.',
    technologies: ['Microsoft Teams', 'Sharepoint', 'Power BI', 'Power Apps', 'Power Automate', 'Power Virtual Agents'],
    challenges: 'Mineração de dados; Segurança; Performance; Escala; Orquestração de serviços; Regra de negócios',
    results: 'Um estudo de mercado leva em média de 30 à 45 dias para ser realizado, mas com o uso da plataforma este tempo passou a ser de 5 minutos em média. Com isto foram gerados mais de 1k estudos mercados em um único ano.',
    mainResults: [
      'https://neocom.info/assets/gig.png',
    ],
    partners: ['/images/ms365-costumers.png'],
    links: [
      {
        link: 'https://powerplatform.microsoft.com/pt-br/',
        iconType: 'site',
      },
    ],
    costumer: {
      logo: '',
    },
  },
];

export const clientsLogos = [
  {
    img: '/images/odebrecht.png',
    title: 'odebrecht',
  },
  {
    img: '/images/walmart.png',
    title: 'walmart',
  },
  {
    img: '/images/iel.png',
    title: 'iel',
  },
  {
    img: '/images/sebrae.png',
    title: 'sebrae',
  },
  {
    img: '/images/caixa.png',
    title: 'caixa',
  },
  {
    img: '/images/senai.png',
    title: 'senai',
  },
  {
    img: '/images/toyota.png',
    title: 'toyota',
  },
  {
    img: '/images/jeep.png',
    title: 'jeep',
  },
  {
    img: '/images/fiat.png',
    title: 'fiat',
  },
];
