import React from 'react';
import { Image, LandingImage } from './style';

function ExampleImage() {
  return (
    <LandingImage>
      <Image />
    </LandingImage>
  );
}

export default ExampleImage;
