import styled, { keyframes } from 'styled-components';
import Marquee from 'react-fast-marquee';

const marquee = keyframes`
  to {
    transform: translateX(-100%)
  }
`;

export const DivHover = styled.div`
  pointer-events: none;
  position: fixed;
  width: 10px;
  height: 10px;
  border: solid 1px #fff;
  border-radius: 50%;
  transition: 0.2s ease-out;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FastMarquee = styled.p`
  font-size: 55px;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 25px;
  margin: 10px 100px;
  position: ${(props) => (props.elevate ? 'relative' : 'unset')};
  top: -20px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  z-index: 4;
  line-height: 100;

  @media (max-width: 780px) {
    font-size: 45px;
    margin: 0 60px;
  }

  @media (max-width: 475px) {
    font-size: 36px;
  }

  @media (max-width: 375px) {
    font-size: 32px;
    top: -12px;
  }
`;

export const Span = styled.span`
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
`;

export const SlowerMarquee = styled(FastMarquee)`
  animation: ${marquee} 60s linear infinite;
  font-size: 30px;

  
  @media (max-width: 375px) {
    font-size: 16px;
  }
`;

export const Container = styled.section`
  /* position: fixed;
  top: 0;
  left: 0; */
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #0a0a0a;
  padding-top: 85px;
  z-index: 30;
  padding-bottom: 100px;

  @media (max-width: 960px) {
    position: unset;
    top: unset;
    left: unset;
    height: 100vh;
  }

  @media (max-width: 600px) {
    padding-bottom: 28%;
  }

  @media (max-width: 600px) {
    padding-bottom: 28%;
  }

  @media (max-width: 375px) {
    padding-bottom: 35%;
  }
`;

export const SectionTitle = styled.h1`
  color: #fff;
  align-self: center;
  font-size: 48px;
  padding: 0 2%;
  margin-bottom: 60px;
  margin-top: 40px;

  @media (max-width: 780px) {
    font-size: 50px;
    margin-top: 50px;
    text-align: center;
  }
`;

export const MarqueeContainer = styled(Marquee)`
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 35px 0;
`;
