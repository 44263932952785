import styled from 'styled-components';
import { CommonText } from '../../../../../common/theme';

export const MainContainer = styled.div`
  display: none;
  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
`;

export const Container = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: space-around;
`;

export const CanvasContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 60px;

  @media (max-width: 825px) {
    justify-content: center;
    margin-right: 0;
  }
`;

export const Canvas = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageLogo = styled.img.attrs({
  src: '/icons/normal-logo.svg',
  alt: 'objectives',
})`
  max-width: 360px;
  display: none;

  @media (max-width: 875px) {
    display: block;
  }

  @media (max-width: 465px) {
    max-width: 300px;
  }
`;

export const ContentContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  @media (max-width: 960px) {
    width: 100% !important;
  }
`;

export const ScrollIconContainer = styled.div`
  height: 50px;
  width: 50px;
  background-color: #000;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled(CommonText)`
  font-size: 14px;
  margin-top: 10px;
`;

export const ContentAminContainer = styled.div`
  width: 100%;
  padding: 0 2%;
`;
