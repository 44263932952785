import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  @media (max-width: 960px) {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const Overlay = styled.div`
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 0.2s ease-in-out;
  pointer-events: none;
  opacity: 0;
  z-index: 110;
  ${({ open }) => open && css`
      opacity: 0.3;
      right: 0;
      pointer-events: unset;
    `}
`;
