import React from 'react';
import PropTypes from 'prop-types';
import ParallaxContainer from '../../../../common/components/Parallax';

import {
  Container,
  TitleText,
  Text,
  CardsContainer,
  Card,
  CardContent,
  CardTitle,
  CardImage,
  Content,
  SideText,
  CardText,
} from './style';

const strings = {
  title: 'Concentre-se nos resultados. Não no escopo.',
  telescopeTitle: 'Concentre-se nos resultados.',
  titleCommunication: 'Comunicação Direta e Entrega Contínua',
  cardText: 'Todos nós temos grandes hipóteses, mas ninguém pode prever o futuro.',
  text: 'Lorem ipsum lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsum',
  communicationSub: 'Nós nos esforçamos para ter uma comunicação muito próxima e frequente.',
  communicationText: 'Toda semana você define as metas. Nós priorizamos juntos e ajudamos você a fazê-lo. Temos reuniões diárias face a face para verificar o progresso. A comunicação diária e os resultados semanais geram constantes iterações para a realização.',
  communicationCard: 'Sempre alinhado',
  dnaCard: 'Nosso DNA',
  dnaTitle: 'Humano. Simples. Criativo.',
  dnaSub: 'Deve ser Humano, Simples e Criativo.',
  dnaText: 'Cada pedacinho do trabalho que fazemos é guiado por essas três frentes simples. Deve ser Humano, Simples e Criativo. É a nossa lista de como acreditamos que as coisas devem ser feitas para fortalecer mudanças positivas.',
  resultadosText: 'Como empresa, entendemos que o caminho para um produto de sucesso não é uma linha reta. Nosso relacionamento nos permite mudar de rota quantas vezes precisarmos para chegar ao melhor resultado.',
};

function Plan({ scrollRef, planContainer, hidden }) {
  return (
    <Container ref={planContainer} expandContainer={hidden}>
      <CardsContainer>
        <Content reverse noMargin>
          <Card telescopeCard>
            <ParallaxContainer scrollRef={scrollRef}>
              <CardImage src="/images/telescope.png" telescopeCard />
            </ParallaxContainer>
            <CardContent telescopeCard>
              <CardTitle>{strings.telescopeTitle}</CardTitle>
            </CardContent>
          </Card>
          <SideText>
            <TitleText>{strings.title}</TitleText>
            <Text>{strings.cardText}</Text>
            <CardText telescopeCard>{strings.resultadosText}</CardText>
          </SideText>
        </Content>
      </CardsContainer>

      <CardsContainer>
        <Content>
          <Card communicationCard>
            <ParallaxContainer scrollRef={scrollRef}>
              <CardImage src="/images/megaphone.png" communicationCard />
            </ParallaxContainer>
            <CardContent communicationCard>
              <CardTitle normalText>{strings.communicationCard}</CardTitle>
            </CardContent>
          </Card>
          <SideText reverse>
            <TitleText>{strings.titleCommunication}</TitleText>
            <Text>{strings.communicationSub}</Text>
            <CardText>{strings.communicationText}</CardText>
          </SideText>
        </Content>
      </CardsContainer>

      <CardsContainer>
        <Content reverse>
          <Card>
            <ParallaxContainer scrollRef={scrollRef}>
              <CardImage src="/images/dna.png" dnaCard />
            </ParallaxContainer>
            <CardContent dnaCard>
              <CardTitle normalText dnaCard>
                {strings.dnaCard}
              </CardTitle>
            </CardContent>
          </Card>
          <SideText>
            <TitleText>{strings.dnaTitle}</TitleText>
            <Text>{strings.dnaSub}</Text>
            <CardText>{strings.dnaText}</CardText>
          </SideText>
        </Content>
      </CardsContainer>
    </Container>
  );
}

Plan.defaultProps = {
  scrollRef: null,
  planContainer: null,
  hidden: false,
};

Plan.propTypes = {
  hidden: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  scrollRef: PropTypes.any,
  planContainer: PropTypes.any,
};

export default Plan;
