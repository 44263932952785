import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  SubTitle,
  Title,
  AboutContent,
  Text,
  Image,
  CustomButton,
  ContentContainer,
  ImagesContainer,
  PaymentImages,
  LimitContainer,
} from './style';

function AboutPayment({ paymentRef }) {
  const contactRedirect = () => {
    window.location.href = 'https://forms.office.com/Pages/ResponsePage.aspx?id=4RvYq0R4TUec2ZqRTgFTb7pY9xk6bRlIn_rl0dpdsQhUNUFBTEcyR1czVzNRQTM2Tzk1R1dNQ0VQNiQlQCN0PWcu';
  };

  return (
    <Container ref={paymentRef}>
      <LimitContainer>
        <ContentContainer>
          <AboutContent>
            <Title>Sua empresa vendendo online!</Title>
            <Text>
              Além de todo o controle da sua empresa o i9.Platform também
              oferece a possibilidade de gerar vendas totalmente online!
            </Text>
          </AboutContent>
          <AboutContent>
            <SubTitle>Monte o pedido, envie o link, venda online!</SubTitle>
            <Text>
              Nosso sistema de vendas online aceita pagamentos em cartão de
              crédito, boleto e PIX!
            </Text>
          </AboutContent>
          <ImagesContainer>
            <PaymentImages src="/images/elo.png" />
            <PaymentImages src="/images/mastercard.png" />
            <PaymentImages src="/images/visa.png" />
            <PaymentImages src="/images/pix.png" />
            <PaymentImages src="/images/boleto.png" />
          </ImagesContainer>
          <AboutContent>
            <CustomButton
              text="Entre em contato"
              darkMode
              lightText
              action={() => contactRedirect()}
            />
          </AboutContent>
        </ContentContainer>
        <Image />
      </LimitContainer>
    </Container>
  );
}

AboutPayment.defaultProps = {
  paymentRef: null,
  // handleOpen: () => null,
};

AboutPayment.propTypes = {
  paymentRef: PropTypes.any,
  // handleOpen: PropTypes.func,
};

export default AboutPayment;
