import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  SubTitle,
  Title,
  AboutContent,
  Text,
  Image,
  // CustomButton,
} from './style';

function Footer({ footerRef }) {
  return (
    <Container ref={footerRef}>
      <AboutContent>
        <SubTitle>Conecte-se.</SubTitle>
        <Title>Reúna suas ferramentas, pessoas e processos</Title>
        <Text>
          Personalize seu espaço de trabalho com aplicativos, APIs abertas,
          plugins poderosos e webhooks.
        </Text>
        {/* <CustomButton text="Veja Mais" darkMode lightText /> */}
      </AboutContent>
      <Image />
    </Container>
  );
}

Footer.defaultProps = {
  footerRef: null,
};

Footer.propTypes = {
  footerRef: PropTypes.any,
};

export default Footer;
