import styled from 'styled-components';

export const LandingImage = styled.div``;

export const Image = styled.img.attrs({
  src: '/images/example-omni.png',
  alt: 'Omnichannel',
})`
  min-width: 650px;
  max-width: 100%;
  position: relative;
  right: -15px;
  padding-left: 15px;

  @media (max-width: 1080px) {
    min-width: unset;
    justify-self: flex-end;
    max-width: 680px;
    position: relative;
    right: -100px;
  }

  @media (max-width: 595px) {
    max-width: 590px;
  }

  @media (max-width: 485px) {
    max-width: 500px;
  }

  @media (max-width: 390px) {
    max-width: 470px;
  }

  @media (max-width: 375px) {
    max-width: 430px;
  }

  @media (max-width: 330px) {
    max-width: 350px;
  }
`;
