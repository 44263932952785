import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  MarqueeContainer,
  FastMarquee,
  SlowerMarquee,
  Span,
  SectionTitle,
  // DivHover,
} from './style';

function Stack({ sectionRef }) {
  // const containerReference = useRef(null);
  // const cursor = useRef(null);

  // const handleMouseCursor = (e) => {
  //   const x = e.clientX;
  //   const y = e.clientY;
  //   cursor.current.style.left = x + "px";
  //   cursor.current.style.top = y + "px";
  // };

  // const handleOnHover = (e) => {
  //   if (
  //     (e.target && e.target.nodeName === "P") ||
  //     e.target.nodeName === "SPAN"
  //   ) {
  //     cursor.current.style.width = "200px";
  //     cursor.current.style.height = "250px";
  //     cursor.current.style.borderRadius = "5px";
  //     cursor.current.style.background = "#fff";
  //   } else {
  //     cursor.current.style.width = "20px";
  //     cursor.current.style.height = "20px";
  //     cursor.current.style.borderRadius = "50%";
  //     cursor.current.style.background = "unset";
  //   }
  // };

  // const handleMouseEnterContainer = () => {
  //   cursor.current.style.opacity = "1";
  // }

  // const handleMouseOutContainer = () => {
  //   cursor.current.style.opacity = "0";
  // }

  // useEffect(() => {
  //   containerReference.current.addEventListener("mousemove", (e) => handleMouseCursor(e));
  //   containerReference.current.addEventListener("mouseover", (e) => handleOnHover(e));
  //   containerReference.current.addEventListener("mouseenter", () => handleMouseEnterContainer());
  //   containerReference.current.addEventListener("mouseleave", () => handleMouseOutContainer());

  //   return () => {
  //     containerReference.current.removeEventListener("mousemove", (e) => handleMouseCursor(e));
  //     containerReference.current.removeEventListener("mouseover", (e) => handleOnHover(e));
  //     containerReference.current.removeEventListener(
  //      "mouseenter", () => handleMouseEnterContainer()
  //    );
  //     containerReference.current.removeEventListener(
  //       "mouseleave", () => handleMouseOutContainer()
  //    );
  //   };
  // }, []);

  return (
    <Container id="stack" ref={sectionRef}>
      <SectionTitle>Nossa Stack 💪</SectionTitle>
      <MarqueeContainer gradient={false} speed={37}>
        <FastMarquee>Cosmos</FastMarquee>
        <FastMarquee elevate>
          <Span>Oracle</Span>
        </FastMarquee>
        <FastMarquee>
          <Span>MySQL</Span>
        </FastMarquee>
        <FastMarquee elevate>React</FastMarquee>
        <FastMarquee>
          <Span>React Native for Web</Span>
        </FastMarquee>
        <FastMarquee elevate>Redux</FastMarquee>
        <FastMarquee>
          <Span>Styled-components</Span>
        </FastMarquee>
        <FastMarquee elevate>React Native for iOS e Android</FastMarquee>
        <FastMarquee>
          <Span>PWA</Span>
        </FastMarquee>

        <FastMarquee elevate>Python</FastMarquee>
        <FastMarquee>
          <Span>Jupyter Notebooks</Span>
        </FastMarquee>
        <FastMarquee elevate>Scikit-learn</FastMarquee>
        <FastMarquee>
          <Span>Auto Arima</Span>
        </FastMarquee>
        <FastMarquee elevate>Xg Boost</FastMarquee>
        <FastMarquee>
          <Span>Metabase</Span>
        </FastMarquee>
        <FastMarquee elevate>Power BI</FastMarquee>
        <FastMarquee>
          <Span>Data Flow</Span>
        </FastMarquee>

        <FastMarquee elevate>
          <Span>ArcGIS</Span>
        </FastMarquee>
        <FastMarquee>Pix4D Engine</FastMarquee>
      </MarqueeContainer>
      <MarqueeContainer gradient={false} speed={22}>
        <SlowerMarquee><Span>Postgresql</Span></SlowerMarquee>
        <SlowerMarquee>HTML 5</SlowerMarquee>
        <SlowerMarquee>
          <Span>Bootstrap</Span>
        </SlowerMarquee>
        <SlowerMarquee>Material UI</SlowerMarquee>
        <SlowerMarquee>
          <Span>GraphQl</Span>
        </SlowerMarquee>
        <SlowerMarquee>Hasura</SlowerMarquee>
        <SlowerMarquee>
          <Span>AMP</Span>
        </SlowerMarquee>
        <SlowerMarquee>ThreeJs</SlowerMarquee>
        <SlowerMarquee>
          <Span>Web socket</Span>
        </SlowerMarquee>

        <SlowerMarquee>Microsoft Azure</SlowerMarquee>
        <SlowerMarquee>
          <Span>Google Cloud</Span>
        </SlowerMarquee>
        <SlowerMarquee>Amazon AWS</SlowerMarquee>
        <SlowerMarquee>
          <Span>Scrum</Span>
        </SlowerMarquee>
        <SlowerMarquee>Shape-up</SlowerMarquee>
        <SlowerMarquee>
          <Span>Design Thinking</Span>
        </SlowerMarquee>
        <SlowerMarquee>Product Discovery</SlowerMarquee>
        <SlowerMarquee>
          <Span>Microsoft 365</Span>
        </SlowerMarquee>

        <SlowerMarquee>Sharepoint Online</SlowerMarquee>
        <SlowerMarquee>
          <Span>Power Automate</Span>
        </SlowerMarquee>
        <SlowerMarquee>Power Apps</SlowerMarquee>
        <SlowerMarquee>
          <Span>Power BI</Span>
        </SlowerMarquee>
      </MarqueeContainer>
      <MarqueeContainer gradient={false} speed={37}>
        <FastMarquee elevate>NextJs</FastMarquee>
        <FastMarquee>
          <Span>TypeScript</Span>
        </FastMarquee>
        <FastMarquee elevate>
          <Span>C#</Span>
        </FastMarquee>
        <FastMarquee>.Net Core Framework</FastMarquee>
        <FastMarquee elevate>
          <Span>Blazor</Span>
        </FastMarquee>
        <FastMarquee>Signal R</FastMarquee>
        <FastMarquee elevate>
          <Span>Azure Communication Services</Span>
        </FastMarquee>
        <FastMarquee>Azure Bot Service</FastMarquee>
        <FastMarquee elevate>
          <Span>Azure Functions</Span>
        </FastMarquee>
        <FastMarquee>Unit Test</FastMarquee>
        <FastMarquee elevate>
          <Span>Nodejs</Span>
        </FastMarquee>
        <FastMarquee>Python</FastMarquee>
        <FastMarquee elevate>
          <Span>Django</Span>
        </FastMarquee>
        <FastMarquee>SQL Server</FastMarquee>

        <FastMarquee elevate>
          <Span>Teams</Span>
        </FastMarquee>
        <FastMarquee>Google</FastMarquee>
        <FastMarquee elevate>
          <Span>Google Workspace</Span>
        </FastMarquee>
        <FastMarquee>Geoprocessamento e 3D </FastMarquee>
      </MarqueeContainer>
      {/* <DivHover ref={cursor} id="aaa"></DivHover> */}
    </Container>
  );
}

Stack.defaultProps = {
  sectionRef: null,
};

Stack.propTypes = {
  sectionRef: PropTypes.any,
};

export default Stack;
