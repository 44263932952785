import styled from 'styled-components';

export const FirstSection = styled.div`
  min-width: 650px;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-left: 50px;
  padding-top: 10%;

  @media (max-width: 1225px) {
    margin-left: 25px;
  }

  @media (max-width: 960px) {
    min-height: unset;
    margin-left: 0px;
    margin-top: 50px;
    justify-content: flex-start;
    width: 100%;
  }

  @media (max-width: 725px) {
    min-width: unset;
    width: 100%;
    justify-content: flex-start;
  }
`;

export const DescriptionSection = styled(FirstSection)`
  margin-left: -75px;
  @media (max-width: 1225px) {
    margin-left: 0;
  }

  @media (max-width: 960px) {
    min-width: unset;
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;

  @media (max-width: 960px) {
    padding: 0;
  }

  @media (max-width: 725px) {
    max-width: 85%;
    padding: 0;
  }
`;

export const Title = styled.h1`
  @media (max-width: 725px) {
    width: 100%;
  }

  @media (max-width: 475px) {
    font-size: 30px;
  }
`;

export const SubTitle = styled.h2`
  font-weight: 100;
  margin-top: ${(props) => (props.descriptionTitle ? '10px' : '-15px')};
  margin-bottom: ${(props) => (props.descriptionTitle ? '10px' : '20px')};

  @media (max-width: 475px) {
    margin-bottom: ${(props) => (props.descriptionTitle ? '10px' : '20px')};
  }
`;

export const CostumerLogo = styled.img`
  max-width:228px;
  max-height:89px;
  width: auto;
  height: auto;
  margin-bottom: 24px;
  object-fit: cover;

  @media (max-width: 960px) {
    height: auto;
  }
`;

export const DescriptionContent = styled.div`
  @media (max-width: 960px) {
    margin-left: ${(props) => (props.partners ? 'unset' : '0')};
    max-width: ${(props) => (props.partners ? '650px' : '100%')};

    img {
      max-width: 100%;
      height: auto;
    }
  }
`;

export const DescriptionContainer = styled.div`
  max-width: ${(props) => (props.lastSection ? '600px' : '500px')};
  margin-left: 36px;
  display: flex;
  flex-direction: ${(props) => (props.lastSection ? 'column' : 'row')};
  justify-content: center;
  flex-direction: column;

  @media (max-width: 960px) {
    max-width: 100%;
    margin-left: 0;
  }
`;

export const GalleryTitle = styled.h1`
  margin-right: 220px;
  margin-left: 25px;
  width: 100px;
  font-size: 3rem;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const SecondSection = styled.div`
  min-width: max-content;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 24px;
  padding-bottom: 4%;

  span {
    font-weight: 100;
  }

  @media (max-width: 960px) {
    min-width: unset;
    background: transparent;
    width: 100%;
    height: unset;
  }
`;

export const Span = styled.span`
  font-weight: 100;
`;

export const GaleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 960px) {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const Projectimage = styled.img`
  max-width: ${({ limitedImage }) => (limitedImage ? '1000px' : '100%')};
  height: auto;
  max-height: 60%;
  object-fit: ${({ limitedImage }) => (limitedImage ? 'cover' : 'contain')};
  margin-right: 100px;
  border-radius: ${({ limitedImage }) => (limitedImage && '10px')};
  box-shadow: ${({ limitedImage }) => (limitedImage && '5px 5px 30px 1px rgba(221, 211, 211, 0.2)')};

  @media (max-width: 960px) {
    max-width: 700px;
    margin-right: 0;
  }

  @media (max-width: 770px) {
    width: 100%;
    height: 280px;
  }
`;

export const ThirdSection = styled(FirstSection)`
  min-width: 80vw;
  justify-content: start;
  align-items: center;
  padding: 0 24px;

  p {
    max-width: 800px;
  }
`;

export const LinkContainer = styled.a`
  border: solid 2px #fff;
  padding: 12px 16px;
  margin-top: 20px;
  border-radius: 50px;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  font-size: 14px;
  max-width: 350px;

  &:hover {
    background: #fff;
  }
`;

export const TechInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TechInfoText = styled.p`
  max-width: 400px;
  font-size: 14px;

  border: 2px solid #fff;
  border-radius: 50px;
  padding: 2px 24px;
  margin: 3px 0;
  margin-right: 6px;

  @media (max-width: 414px) {
    max-width: 300px;
  }
`;

export const LinkIcon = styled.img.attrs({
  alt: 'link-icon',
})`
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 12px;
`;

export const Text = styled.p`
  color: #121b22;
  line-height: 32px;
  white-space: pre-line;

  @media (max-width: 960px) {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
  }
`;

export const LinkText = styled.p`
  width: 100%;
  word-wrap: wrap;
`;

export const PartnerContainer = styled.div``;

export const PartnerItem = styled.div``;

export const PartnerLogo = styled.div``;

export const PartnerName = styled.div``;

export const About = styled.div`
  max-width: 650px;
`;

export const MobileHeader = styled.div`
  display: none;
  transition: 0.3s ease-in;
  background: ${({ changeBackground }) => (changeBackground ? '#191a1b' : 'transparent')};
  button {
    margin-right: -3px;
  }

  @media (max-width: 960px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 0 24px;
  }
`;
