import styled from 'styled-components';
// import { motion } from 'framer-motion';
import { FadeAnimContainer } from '../../common/theme';

export const ContainerModal = styled(FadeAnimContainer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  overflow-x: hidden;
  overflow-y: hidden;
  display: ${({ open }) => (open ? 'flex' : 'none')};

  z-index: 1;
  @media (max-width: 960px) {
    padding: 0 24px;
    overflow-x: hidden;
    display: block;
    /* flex-direction: column; */
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: fixed;
  top: 25px;
  right: 24px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    right: 3%;
  }
`;

export const CloseIcon = styled.img.attrs({
  src: '/icons/close.svg',
  alt: 'Close icon',
})`
  width: 16px;
  height: 16px;
  z-index: 1;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const ChevronLeft = styled.img.attrs({
  src: '/icons/chevron-left.svg',
  alt: 'Close icon',
})`
  width: 16px;
  height: 16px;
`;

export const ChevronRight = styled.img.attrs({
  src: '/icons/chevron-right.svg',
  alt: 'Close icon',
})`
  width: 16px;
  height: 16px;
`;

export const Span = styled.span`
  font-weight: 100;
`;

export const ContainerControls = styled.div`
  position: fixed;
  bottom: 28px;
  left: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 960px) {
    display: none;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    top: 24px;
    right: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
  }
`;

export const ButtonBack = styled.button``;

export const StepButtonsContainer = styled.div`
  display: flex;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const PreviusStepButton = styled.button`
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  border: solid 2px #dae3dc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  transition: 0.3s ease;

  &:hover {
    background: #fff;
    border: solid 2px #fff;
  }
`;

export const NextStepButton = styled(PreviusStepButton)`
  padding: 6px;
  margin-left: 15px;
  margin-right: 35px;

  @media (max-width: 600px) {
    margin-right: 10px;
  }
`;

export const StatusBar = styled.div``;

export const Logo = styled.img.attrs({
  src: '/icons/header-logo.svg',
  alt: 'objectives',
})`
  position: fixed;
  top: 28px;
  left: 24px;
  z-index: 1;
  max-width: 100px;
  
  @media (max-width: 960px) {
    display: none;
  }

  @media (max-width: 600px) {
    left: 3%;
  }
`;

export const LogoMobile = styled.img.attrs({
  alt: 'objectives',
})`
  max-width: 100px;
`;

export const ProgressContainer = styled.div`
  height: 3px;
  border-radius: 20px;
  width: 600px;
  background: #dae3dc;
  position: relative;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const ProgressBar = styled.div`
  background: #191a1b;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  opacity: 1;
`;

export const MobileHeader = styled.div`
  display: none;
  transition: 0.3s ease-in;
  background: ${({ changeBackground }) => (changeBackground ? '#191a1b' : 'transparent')};
  button {
    margin-right: -3px;
  }

  @media (max-width: 960px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 0 24px;
  }
`;
