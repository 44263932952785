import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Title,
  AboutContent,
  Text,
  Image,
  CustomButton,
  MicrosoftImage,
  LimitContainer,
} from './style';

function AboutMicrosoft({ footerRef }) {
  const contactRedirect = () => {
    window.location.href = 'https://forms.office.com/Pages/ResponsePage.aspx?id=4RvYq0R4TUec2ZqRTgFTb7pY9xk6bRlIn_rl0dpdsQhUNUFBTEcyR1czVzNRQTM2Tzk1R1dNQ0VQNiQlQCN0PWcu';
  };

  return (
    <Container ref={footerRef}>
      <LimitContainer>
        <AboutContent>
          <MicrosoftImage src="/images/microsoft.png" />
          <Title>i9.Platform libera para sua empresa os serviços do Microsoft Office 365!</Title>
          <Text>
            O i9.Platform além de ser desenvolvido com a mesma tecnologia utilizada pela Microsoft,
            também é totalmente integrado com o Microsoft 365®.
          </Text>
          <CustomButton text="Entre em contato" darkMode lightText action={() => contactRedirect()} />
        </AboutContent>
        <Image />
      </LimitContainer>
    </Container>
  );
}

AboutMicrosoft.defaultProps = {
  footerRef: null,
  // handleOpen: () => null,
};

AboutMicrosoft.propTypes = {
  footerRef: PropTypes.any,
  // handleOpen: PropTypes.func,
};

export default AboutMicrosoft;
