import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SoftwareDevelopment from './softwareDevelopment';
import MobileContainer from './mobileContainer';

import {
  MainContainer,
  Container,
  SectionTitle,
  Title,
  TitleSection,
  Text,
  Options,
  OptionsContainer,
  Consultancy,
  ConsultancyContainer,
  ConsultancyContent,
  Outsourcing,
  OutsourcingContainer,
  TextAnimated,
  AnimatedTitle,
  OutsourcingTitle,
  OutsourcingContent,
} from './style';

const strings = {
  title: 'O que fazemos',
  text: 'Nossas soluções partem do entendimento do negócio, com foco em atender a todas as demandas e expectativas da empresa.',
  software: 'Squad as a Service',
  consultancy: 'Consultoria Ágil',
  outsourcing: 'Outsourcing',
  consultancyText:
    'Utilizamos as melhores práticas das metodologias ágeis (Scrum, XP e Lean) que auxiliam na organização de grandes entregas de forma que sejam rápidas e agreguem valor para a empresa de forma iterativa e incremental. Nossa equipe altamente experiente sabe onde acontece verdadeiramente o impacto da adoção de uma nova metodologia dentro de uma empresa. Por isso nosso diferencial de atuação é entrar no cerne da questão de adoção do ágil na cultura do cliente.',
  outsourcingText:
    'Com a finalidade de facilitar processos e alavancar a capacidade de produção do cliente, oferecemos o serviço de Outsourcing de TI com foco em recursos humanos. Nossa equipe é formada por profissionais de TI especializados em diversas frentes técnicas e de negócio, agregamos conhecimentos em áreas do cliente sem as burocracias de uma contratação interna. Podemos trabalhar em modelo de horas abertas ou pacote de trabalho fechado por projeto.',
};

function WhatWeDo({ sectionRef, whatWeDoSection }) {
  const [isHover, setIsHover] = useState(false);
  const [consultancyHover, setConsultancyHover] = useState(false);
  const [outsourcingHover, setOutsourcingHover] = useState(false);

  const handleFirstTitle = () => {
    if (isHover) return 'visible';
    if (consultancyHover || outsourcingHover) return 'hidden';
    return 'normal';
  };

  return (
    <MainContainer
      id="whatwedo"
      consultancyhover={consultancyHover.toString()}
      outsourcinghover={outsourcingHover.toString()}
      ref={sectionRef}
    >
      <Container ref={whatWeDoSection}>
        <SectionTitle>
          <Title>{strings.title}</Title>
          <Text>{strings.text}</Text>
        </SectionTitle>

        <OptionsContainer>
          {/* Software Factory */}
          <Options
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <TitleSection
              smalltitle="true"
              initial="normal"
              animate={handleFirstTitle()}
            >
              {strings.software}
            </TitleSection>
            <SoftwareDevelopment initial="hidden" isHover={isHover} />
          </Options>

          {/* Consultancy */}
          <Consultancy
            onMouseEnter={() => setConsultancyHover(true)}
            onMouseLeave={() => setConsultancyHover(false)}
            animate={consultancyHover ? 'visible' : 'hidden'}
          >
            <AnimatedTitle
              smalltitle="true"
              animate={consultancyHover ? 'visible' : 'hidden'}
            >
              {strings.consultancy}
            </AnimatedTitle>
            <ConsultancyContainer
              initial="hidden"
              animate={consultancyHover ? 'visible' : 'hidden'}
            >
              <ConsultancyContent>
                <TextAnimated>{strings.consultancyText}</TextAnimated>
              </ConsultancyContent>
            </ConsultancyContainer>
          </Consultancy>

          {/* Outsourcing */}
          <Outsourcing
            onMouseEnter={() => setOutsourcingHover(true)}
            onMouseLeave={() => setOutsourcingHover(false)}
            animate={outsourcingHover ? 'visible' : 'hidden'}
          >
            <OutsourcingTitle smalltitle="true">{strings.outsourcing}</OutsourcingTitle>
            <OutsourcingContainer
              initial="hidden"
              animate={outsourcingHover ? 'visible' : 'hidden'}
            >
              <OutsourcingContent>
                <TextAnimated>{strings.outsourcingText}</TextAnimated>
              </OutsourcingContent>
            </OutsourcingContainer>
          </Outsourcing>

        </OptionsContainer>
        <MobileContainer />
      </Container>
    </MainContainer>
  );
}

WhatWeDo.defaultProps = {
  sectionRef: null,
  whatWeDoSection: null,
};

WhatWeDo.propTypes = {
  sectionRef: PropTypes.any,
  whatWeDoSection: PropTypes.any,
};

export default WhatWeDo;
