import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 40%;
  position: fixed;
  top: 0;
  right: 0;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  justify-content: center;
  align-items: center;
  padding: 2% 2%;
  z-index: 130;
  background: #f2f2f2;
  transition: all 0.3s ease;
  border-left: 1px solid rgba(26, 27, 28, 0.2);
  overflow-y: auto;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const Icon = styled.img.attrs({
  src: '/icons/mail.svg',
  alt: 'email',
})`
  max-width: 100%;
  height: 18px;
  margin-right: 15px;
`;

export const Title = styled.h1`
  margin-bottom: 54px;
  margin-top: -100px;
  text-align: center;
`;

export const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: -45px;
`;

export const Text = styled.p`
  font-size: 20px;
`;

export const ContactForm = styled.form``;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  position: relative;
  label {
    position: absolute;
    top: 16px;
    left: 0;
    font-size: 1rem;
    pointer-events: none;
  }
`;

export const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: solid 1px #191a1b;
  padding: 0 0 15px;
  margin: 20px 0;
  width: 100%;
`;

export const TextArea = styled.textarea`
  background: transparent;
  border: none;
  border-bottom: solid 1px #191a1b;
  padding: 0 0 15px;
  margin: 20px 0;
  width: 100%;
`;

export const InputCheckBox = styled.input`
  border-radius: 50px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  label {
    font-size: max(12px, min(calc(4px + 0.625vw), 16px));
    margin-left: 6px;
    width: 140px;
  }
`;

export const CloseIcon = styled.img.attrs({
  src: '/icons/close-rounded.png',
  alt: 'close',
})`
  max-width: 30px;
  margin-right: 15px;
`;

export const CloseButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  position: absolute;
  top: 30px;
  right: 0;
`;
