import styled from 'styled-components';

export const Integrations = styled.div`
  margin-top: 50px;
  background-color: #eeebeb;
  /* box-shadow: 2px -12px 20px 1px rgba(177, 171, 171, 0.1); */
  width: 100%;
  padding: 24px 2%;
  display: flex;
  align-items: flex-start;
  gap: 26px;
  justify-content: space-between;

  @media (max-width: 960px) {
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

export const Icon = styled.img`
  max-width: 65px;
  width: 100%;

  @media (max-width: 780px) {
    max-width: 38px;
  }
`;

export const IconContainer = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ facebookIcon }) => (facebookIcon ? '25px' : '15px')};

  @media (max-width: 1085px) {
    max-width: 100%;
    gap: 15px;
  }
`;

export const Text = styled.p`
  color: #878c9b;
  margin-top: 10px;
  width: 100%;

  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 18px;
    line-height: 32px;
  }
`;

export const LimitContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 2%;

  @media (max-width: 1085px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
`;
