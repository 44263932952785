import React from 'react';
import PropTypes from 'prop-types';
import { UpAnimContainer } from '../../common/theme';
import Button from '../../common/components/Button';
import LinkButton from '../../common/components/Link';
// import { Link } from "react-router-dom";

import {
  ContainerAdmin,
  HeaderContainer,
  Logo,
  Separator,
  HeaderContent,
  ContactButtons,
  Links,
  // LinkPlatform,
} from './style';

function Header({
  // handleOpen,
  // handleClose,
  open,
  // section,
  darkMode,
  onChangeSection,
  showLinks,
  projectsLink,
  stackLink,
  whatWeDoLink,
}) {
  const contactRedirect = () => {
    window.location.href = 'https://forms.office.com/Pages/ResponsePage.aspx?id=4RvYq0R4TUec2ZqRTgFTb7pY9xk6bRlIn_rl0dpdsQhUNUFBTEcyR1czVzNRQTM2Tzk1R1dNQ0VQNiQlQCN0PWcu';
  };

  const shopRedirect = () => {
    window.location.href = 'https://shop.i9tech.co/';
  };

  return (
    <ContainerAdmin darkMode={darkMode}>
      <HeaderContainer>
        <HeaderContent>
          <UpAnimContainer>
            {window.innerWidth < 960 ? (
              <Logo
                src={`/icons/header-logo${!open && darkMode ? '-white' : ''}.svg`}
                animate={darkMode ? 'dark' : 'light'}
                onClick={() => onChangeSection('plan')}
              />
            ) : (
              <Logo
                src={`/icons/header-logo${darkMode ? '-white' : ''}.svg`}
                animate={darkMode ? 'dark' : 'light'}
                onClick={() => onChangeSection('plan')}
              />
            )}
          </UpAnimContainer>
          <ContactButtons>
            {window.innerWidth > 960 && (
              <Links showLinks={showLinks}>
                <LinkButton
                  darkMode={darkMode && !open}
                  text="Loja"
                  style={{ marginLeft: '22px' }}
                  active={false}
                  action={() => shopRedirect()}
                />
                <LinkButton
                  darkMode={darkMode && !open}
                  text="O que fazemos"
                  style={{ marginLeft: '22px' }}
                  active={whatWeDoLink}
                  action={() => onChangeSection('whatwedo')}
                />
                <LinkButton
                  darkMode={darkMode && !open}
                  text="Clientes"
                  style={{ marginLeft: '22px' }}
                  active={projectsLink}
                  action={() => onChangeSection('projects')}
                  sectionName="projects"
                />
                <LinkButton
                  darkMode={darkMode && !open}
                  text="Nossa Stack"
                  style={{ marginLeft: '22px' }}
                  active={stackLink}
                  action={() => onChangeSection('stack')}
                />
                {/* <LinkPlatform to="/i9.Platform">
                  i9.Platform
                </LinkPlatform> */}
              </Links>
            )}

            <Button
              darkMode={darkMode && !open}
              action={() => contactRedirect()}
              text="Entre em contato"
              primaryBtn
            />
          </ContactButtons>
        </HeaderContent>
        <Separator darkMode={darkMode} />
      </HeaderContainer>
    </ContainerAdmin>
  );
}

Header.defaultProps = {
  // handleOpen: () => null,
  // handleClose: () => null,
  open: false,
  darkMode: false,
  showLinks: false,
  whatWeDoLink: false,
  projectsLink: false,
  stackLink: false,
  // section: 0,
  onChangeSection: () => null,
};

Header.propTypes = {
  // handleOpen: PropTypes.func,
  // handleClose: PropTypes.func,
  open: PropTypes.bool,
  darkMode: PropTypes.bool,
  whatWeDoLink: PropTypes.bool,
  projectsLink: PropTypes.bool,
  stackLink: PropTypes.bool,
  showLinks: PropTypes.bool,
  // section: PropTypes.number,
  onChangeSection: PropTypes.func,
};

export default Header;
