import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import ProductsHeader from './containers/ProductsHeader';
import Home from './pages/home';
import Omnichannel from './pages/omnichannel';
import Contact from './containers/contactContainer';
import { Overlay } from './pages/home/style';
import Products from './pages/products';

ReactGA.initialize('G-CXSLENG5PF');

const linkOptions = {
  threshold: 0.9,
};

function App() {
  const [modal, setModal] = useState(false);
  const isHomePage = window.location.pathname === '/';
  const isIndexHtml = window.location.href.includes('index.html');

  const openModal = (open) => {
    if (open) {
      setModal(open);
      window.modalActive = true;
    }
  };

  const closeModal = () => {
    window.modalActive = false;
    setModal('');
  };

  const { ref: OmnichannelFooterRef, inView: OmnichannelFooter } = useInView(linkOptions);
  const { ref: PaymentRef, inView: PaymentOptions } = useInView(linkOptions);

  return (
    <Router>
      <Overlay onClick={() => closeModal()} open={modal === 'contact'} />
      {isIndexHtml && <Navigate to="/" replace />}
      {!isHomePage && (
        <ProductsHeader
          showLinks={!isHomePage}
          darkMode={OmnichannelFooter || PaymentOptions}
          handleOpen={(modalName) => openModal(modalName)}
          handleClose={() => closeModal()}
        />
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/i9.Omnichannel"
          element={<Omnichannel footerRef={OmnichannelFooterRef} />}
        />
        <Route
          path="/i9.Platform"
          element={
            (
              <Products
                footerRef={OmnichannelFooterRef}
                paymentRef={PaymentRef}
                handleOpen={(modalName) => openModal(modalName)}
              />
            )
          }
        />
      </Routes>
      <Contact open={modal === 'contact'} handleClose={closeModal} />
    </Router>
  );
}

export default App;
