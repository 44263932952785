import styled from 'styled-components';
import { motion } from 'framer-motion';
import { CommonText } from '../../../../common/theme';

export const MainContainer = styled(motion.div)`
  display: flex;
  @media (max-width: 960px) {
    display: none;
    flex-direction: column;
  }
`;

export const Container = styled(motion.div).attrs({
  initial: 'expand',
  variants: {
    reduce: {
      width: 300,
      padding: 20,
      transition: {
        delay: 0.4,
        duration: 0.6,
      },
    },
    expand: {
      width: window.innerWidth - 120,
      padding: 20,
      transition: {
        delay: 0.4,
        duration: 0.8,
      },
    },
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        delay: 0.2,
        duration: 0.4,
      },
    },
  },
})`
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 64px); */
  height: 100vh;
  justify-content: flex-end;

  /* margin-top: 50px */
  @media (max-width: 960px) {
    display: none;
  }
`;

export const CanvasContainer = styled(motion.div).attrs({
  initial: 'reduce',
  variants: {
    reduce: {
      opacity: 0,
      display: 'none',
      transition: { duration: 0.6, display: { delay: 0.5 } },
    },
    expand: {
      opacity: 1,
      display: 'flex',
      transition: { duration: 0.6, opacity: { delay: 0.8 } },
    },
  },
})`
  display: flex;
  justify-content: flex-end;
  margin-right: 60px;

  @media (max-width: 825px) {
    justify-content: center;
    margin-right: 0;
  }
`;

export const Canvas = styled.div`
  @media (max-width: 875px) {
    display: none;
  }
`;

export const ImageLogo = styled.img.attrs({
  src: '/icons/normal-logo.svg',
  alt: 'objectives',
})`
  max-width: 360px;
  display: none;

  @media (max-width: 875px) {
    display: block;
  }

  @media (max-width: 465px) {
    max-width: 300px;
  }
`;

export const ContentContainer = styled(motion.div).attrs({
  initial: 'reduce',
  variants: {
    expand: {
      width: '100%',
      transition: {
        delay: 0.4,
        duration: 0.6,
      },
    },
    reduce: {
      width: 120,
      transition: {
        delay: 0.4,
        duration: 0.8,
      },
    },
  },
})`
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  width: ${({ hidden }) => (hidden && '100vw')};
  height: 100vh;

  @media (max-width: 960px) {
    width: 100% !important;
  }
`;

export const Separator = styled(motion.div).attrs({

})`
  background-color: #c8c8c8;
  width: 0.8px;
  height: 100%;
  position: absolute;
  top: 120px;
  left: 0;
  opacity: ${({ hiddenSeparator }) => (hiddenSeparator ? '0' : '1')};
  transition: 0.6s ease;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const ScrollIconContainer = styled.div`
  height: 50px;
  width: 50px;
  background-color: #000;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScrollIcon = styled(motion.img).attrs({
  src: '/icons/scroll.svg',
  alt: 'scroll down icon',
  animate: {
    y: [0, 6, 0],
    transition: {
      y: {
        repeat: Infinity,
        repeatType: 'loop',
        duration: 2,
        ease: 'easeInOut',
      },
    },
  },
})`

`;

export const ScrollContainer = styled(motion.div).attrs({
  initial: 'show',
  variants: {
    hidde: {
      opacity: 0,
      display: 'none',
      transition: { display: { delay: 1 } },
    },
    show: {
      opacity: 1,
      display: 'flex',
      transition: {
        opacity: { delay: 1 },
        display: { delay: 0.5 },
      },
    },
  },
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const Text = styled(CommonText)`
  font-size: 14px;
  margin-top: 10px;
`;

export const ContentAminContainer = styled(motion.div).attrs({
  initial: 'hidde',
  variants: {
    show: {
      display: 'block',
      translateY: [600, 0],
      width: 'calc(100% - 300px)',
      opacity: 1,
      transition: {
        display: {
          delay: 0.5,
        },
        opacity: {
          duration: 0.8,
          delay: 1,
        },
        translateY: {
          duration: 0.4,
          delay: 0.8,
        },
      },
    },
    hidde: {
      display: 'block',
      translateY: [0, 600],
      opacity: 0,
      transition: {
        opacity: {
          duration: 0.1,
          delay: 0.1,
        },
        translateY: {
          duration: 0.5,
        },
        display: {
          delay: 0.6,
        },
      },
    },
  },
})`
  width: 100vw;
  opacity: 0;
  display: none;
  width: 100% !important;
  transition: 0.4s ease;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  @media (max-width: 960px) {
    display: none !important;
  }
`;
