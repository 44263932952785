import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import gsap from 'gsap';
// import { Draggable } from 'gsap/all';
import {
  MainContainer,
  // CarroselContainer,
  Title,
  LogosContainer,
  Container,
  ClientLogo,
  // MobileBox,
} from './style';
import { clientsLogos } from '../../common/constants/staticData';

function Carousel({
  // caseId,
  // openModal,
  show,
  showImages,
  title,
  sectionRef,
}) {
  // const containerRef = useRef();
  // const mobileDevices = window.matchMedia('(max-width: 960px)');
  // const smallDesktop = window.matchMedia('(max-width: 1390px');
  // const xsmallDesktop = window.matchMedia('(max-width: 1085px');
  // const mediumDesktop = window.matchMedia('(max-width: 1550px');
  // const boxClassName = showImages === 'products' ? '.box-products' : '.box';
  // const boxName = showImages === 'products' ? 'box-products' : 'box';
  // let perspectiveRatio = 2000;

  // if (mediumDesktop.matches) {
  //   perspectiveRatio = 1600;
  // }

  // if (smallDesktop.matches) {
  //   perspectiveRatio = 1260;
  // }

  // if (xsmallDesktop.matches) {
  //   perspectiveRatio = 950;
  // }

  // if (mobileDevices.matches) {
  //   perspectiveRatio = 850;
  // }

  // if (mobileDevices.matches) {
  //   perspectiveRatio = 650;
  // }

  // const initCarrosel = () => {
  //   const n = 6;
  //   gsap.registerPlugin(Draggable);

  //   gsap
  //     .timeline()
  //     .set('#carrosel', { perspective: perspectiveRatio });

  //   // eslint-disable-next-line no-plusplus
  //   for (let i = 0; i < n; i++) {
  //     const b = document.createElement('div');

  //     b.classList.add(boxName);
  //     b.setAttribute('id', i);
  //     b.setAttribute('modal', showImages);
  //     containerRef.current.appendChild(b);

  //     gsap.set(b, {
  //       left: '50%',
  //       top: '100%',
  //       x: '-50%',
  //       y: '-50%',
  //       z: 60,
  //       borderRadius: 18,
  //       cursor: 'pointer',
  //     });

  //     gsap.fromTo(
  //       b,
  //       {
  //         scaleY: 0,
  //         // opacity:0.7,
  //         zIndex: () => (i < n / 2 ? -i : i),
  //         // eslint-disable-next-line no-mixed-operators
  //         rotationY: 110 + (i / n) * 160.5,
  //         transformOrigin: String('50% 50% -2500%'),
  //       },
  //       {
  //         scaleY: 1,
  //         duration: 1,
  //         delay: 1 - 0.5 * (i / n),
  //         ease: 'elastic',
  //       },
  //     );

  //     b.onmouseenter = () => {
  //       gsap.to(b, {
  //         duration: 0.3,
  //         scale: 1.05,
  //         ease: 'back.out(6)',
  //       });
  //     };
  //     b.onmouseleave = () => {
  //       gsap.to(b, { duration: 0.4, scale: 1 });
  //     };
  //     b.onmousedown = () => {
  //       gsap.to(b, { scale: 0.9 });
  //     };
  //   }

  //   const handleCarousel = (e) => {
  //     gsap.set(boxClassName, {
  //       duration: 0.9,
  //       rotationY: (i) => 95 + (i / n) * 160.5 + 40 * (e.clientX / window.innerWidth),
  //     });
  //   };

  //   if (!mobileDevices.matches) {
  //     document.addEventListener('mousemove', (e) => handleCarousel(e));
  //   }
  // };

  useEffect(() => {
    // document.addEventListener('click', (e) => {
    //   if (e.target.matches(boxClassName)) {
    //     caseId(parseInt(e.target.id, 10));
    //     openModal(showImages);
    //   }
    // });

    // initCarrosel();
  }, []);

  // const handleMobileImages = () => {
  //   if (showImages === 'products') {
  //     return (
  //       <MobileBox
  //         className="box-products"
  //         id="1"
  //         modal="products"
  //         imagePath="images/ms365.png"
  //       />
  //     );
  //   }

  //   return (
  //     <>
  //       <MobileBox
  //         className="box"
  //         id="1"
  //         modal="costumer-case"
  //         imagePath="images/ms365.png"
  //       />
  //       <MobileBox
  //         className="box"
  //         id="2"
  //         modal="costumer-case"
  //         imagePath="images/neo.jpg"
  //       />
  //       <MobileBox
  //         className="box"
  //         id="0"
  //         modal="costumer-case"
  //         imagePath="images/gecov.jpg"
  //       />
  //       <MobileBox
  //         className="box"
  //         id="3"
  //         modal="costumer-case"
  //         imagePath="images/cryptocase.png"
  //       />
  //       <MobileBox
  //         className="box"
  //         id="4"
  //         modal="costumer-case"
  //         imagePath="images/classeacase.png"
  //       />
  //       <MobileBox
  //         className="box"
  //         id="5"
  //         modal="costumer-case"
  //         imagePath="images/socialcase.png"
  //       />
  //     </>
  //   );
  // };

  return (
    <MainContainer
      id="ourCostumers"
      animate={show ? 'visible' : 'hidden'}
      ref={sectionRef}
      showImages={showImages}
    >
      <Title>{title}</Title>
      <Container>
        {/* <CarroselContainer
          ref={containerRef}
          id="carrosel"
          showImages={showImages}
        /> */}
        <LogosContainer>
          {clientsLogos?.map((item) => (
            <ClientLogo key={item?.title} src={item.img} alt={item.img} />
          ))}
        </LogosContainer>
        {/* {handleMobileImages()} */}
      </Container>
    </MainContainer>
  );
}

Carousel.defaultProps = {
  // caseId: () => null,
  // openModal: () => null,
  sectionRef: null,
  show: false,
  showImages: '',
  title: '',
};

Carousel.propTypes = {
  // caseId: PropTypes.func,
  // openModal: PropTypes.func,
  sectionRef: PropTypes.any,
  show: PropTypes.bool,
  showImages: PropTypes.string,
  title: PropTypes.string,
};

export default Carousel;
