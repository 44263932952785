import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  FlexCol,
  Title,
  Text,
  CardText,
  Image,
} from './style';

const strings = {
  title: 'Entenda como atuamos',
  subTitle: 'Time como serviço, para aumentar a capacidade e liberar a agilitade de seus negócios.',
  text: 'Usamos as mesmas técnicas de criação de produtos que as grandes empresas de tecnologia usam. Oferecemos toda a base para entregar a solução tecnológica que o seu cliente necessita.',
};

function SoftwareDevelopment({ isHover }) {
  return (
    <Container initial="hidden" animate={isHover ? 'visible' : 'hidden'}>
      <FlexCol>
        <Text initial="hidden" animate={isHover ? 'visible' : 'hidden'}>
          {strings.subTitle}
        </Text>
        {/* <Card animate={isHover ? "visible" : "hidden"}> */}
        <Title animate={isHover ? 'visible' : 'hidden'}>
          {strings.title}
        </Title>
        <CardText animate={isHover ? 'visible' : 'hidden'}>
          {strings.text}
        </CardText>
        {/* </Card> */}
      </FlexCol>
      <Image animate={isHover ? 'visible' : 'hidden'} />
    </Container>
  );
}

SoftwareDevelopment.defaultProps = {
  isHover: false,
};

SoftwareDevelopment.propTypes = {
  isHover: PropTypes.bool,
};

export default SoftwareDevelopment;
