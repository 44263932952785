import styled from 'styled-components';
import Button from '../../../../common/components/Button';

export const MainContainer = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 200px 0;

  @media (max-width: 1025px) {
    flex-direction: column;
    gap: 64px;
    padding: 0 4%;
  }
`;

export const I9Image = styled.img.attrs({
  src: '/images/i9-teams.png',
  alt: 'i9.Platform com integração com o excel;',
})`
  max-width: 100%;
`;

export const CardContent = styled.div`
  margin-right: 60px;
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-template-rows: 80px 1fr 60px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  position: relative;
  padding: 22px;
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px rgba(177, 171, 171, 0.2);
  border-radius: 16px;
  width: 100%;
  align-items: center;
  max-width: 550px;

  @media (max-width: 1025px) {
    margin-right: 0;
  }

  @media (max-width: 470px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 70px;
    align-items: flex-start;
  }
`;

export const CardIcon = styled.img.attrs({
  src: '/images/tel.png',
})`
  grid-area: 1 / 1 / 2 / 2;
  margin-right: 2px;

  @media (max-width: 470px) {
    align-self: center;
  }
`;

export const Title = styled.h3`
  grid-area: 1 / 2 / 2 / 3;
  margin-left: 25px;
  font-size: 25px;
  font-weight: 800;
  margin-top: 15px;
  margin-bottom: 25px;
  line-height: 40px;

  
  @media (max-width: 470px) {
    margin-left: 0;
  }
`;

export const Description = styled.p`
  grid-area: 2 / 1 / 3 / 3;
  color: #878c9b;
  margin-top: 10px;
  width: 100%;
  font-size: 18px;
  line-height: 35px;

  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 18px;
    line-height: 32px;
  }
`;

export const CustomButton = styled(Button)`
  grid-area: 3 / 1 / 4 / 3;
  max-width: 175px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 20px;
  z-index: 1;
`;
