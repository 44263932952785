import React from 'react';
import PropTypes from 'prop-types';
import CanvasLogo from '../../../components/canvasLogo';
import Title from '../../../components/title';

import {
  MainContainer,
  Container,
  CanvasContainer,
  Canvas,
} from './style';

function PresentationMobile({ contentScroll }) {
  return (
    <MainContainer ref={contentScroll}>
      <Container>
        <CanvasContainer>
          <Canvas>
            <CanvasLogo mobile />
          </Canvas>
        </CanvasContainer>
        <Title />
      </Container>
    </MainContainer>
  );
}

PresentationMobile.defaultProps = {
  contentScroll: null,
};

PresentationMobile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contentScroll: PropTypes.any,
};

export default PresentationMobile;
