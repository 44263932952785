import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Button = styled.div`
  cursor: pointer;
  /* border-bottom: solid 1px #191a1b; */
  padding-bottom: 6px;
  margin: 0px 12px;
  font-size: max(12px, min(calc(4px + 0.625vw), 16px));
  text-decoration: none;
  position: relative;
  color: #191a1b;
  transition: 0.3s;
  font: 100%;

  ${({ darkMode }) => darkMode && 'color: #fff;'}

  &:after {
    content: "";
    background: #000;
    ${({ darkMode }) => darkMode && 'background: #fff;'}
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 1px;
    transition: 0.3s ease;
    ${({ active }) => active && 'width: 98%;'}
  }
`;
